/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DynamicNavLinkModelGet } from './dynamicNavLinkModelGet';


export interface DynamicHeaderModelGet { 
    id?: number;
    islogoReq?: boolean;
    weblogo?: string | null;
    moblogo?: string | null;
    dualHeader?: boolean;
    logoAlignment?: string | null;
    headerHight?: string | null;
    isSearchReq?: boolean;
    searchType?: string | null;
    isProfileReq?: boolean;
    wishlistReq?: boolean;
    wishlistType?: string | null;
    cartReq?: boolean;
    cartType?: string | null;
    menuReq?: boolean;
    categoryReq?: boolean;
    productReq?: boolean;
    nonCategory?: boolean;
    brandId?: number;
    fontStyle?: string | null;
    fontHeight?: string | null;
    fontWeight?: string | null;
    headerBackgroundColor?: string | null;
    headerTextColor?: string | null;
    solidHeader?: string | null;
    dropDownStyle?: string | null;
    logoHeight?: string | null;
    logoWidth?: string | null;
    logoBorderRadius?: string | null;
    navLinksEndIcon?: boolean | null;
    showAllCategory?: boolean | null;
    getBasePath?: string | null;
    dynamicNavLinkModelGet?: DynamicNavLinkModelGet;
}

