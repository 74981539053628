/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface JobDetailsById { 
    jobRoleName?: string | null;
    jobSpecification?: string | null;
    jobDescription?: string | null;
    jobLocation?: string | null;
    jobQualification?: string | null;
    jobBrief?: string | null;
    jobOpeningId?: number;
    endDate?: string | null;
    createdDate?: string | null;
    noOfRequestsReceived?: number;
}

