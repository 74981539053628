/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetEcomVoucherList { 
    name?: string | null;
    code?: string | null;
    minPrice?: number | null;
    maxDiscount?: number | null;
    discountPercentage?: number | null;
    startsOn?: string;
    expiresOn?: string | null;
    isCrossPlatform?: boolean | null;
    description?: string | null;
    guideLines?: string | null;
    noOfUses?: number | null;
    noOfUsesPerUser?: number | null;
    isVoucher?: boolean | null;
    isFlatDiscount?: boolean | null;
    isCashback?: boolean;
    brandId?: number | null;
    image?: string | null;
    couponId?: number;
    brandName?: string | null;
    brandLogo?: string | null;
}

