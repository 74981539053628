/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EcomBrandwiseApkThemeData { 
    headerBackgroundColor?: string | null;
    headerTextColor?: string | null;
    buttonBackgroundColor?: string | null;
    buttonTextColor?: string | null;
    pageBackgroundColor?: string | null;
    textColor?: string | null;
    topicBackgroundColor?: string | null;
    topicTextColor?: string | null;
    cardBackgroundColor?: string | null;
    cardTextColor?: string | null;
    appLogo?: string | null;
    appLogo1?: string | null;
    appLogo2?: string | null;
    appName?: string | null;
    fontFamily?: string | null;
    razorKey?: string | null;
    razorSecretKey?: string | null;
    isCod?: boolean;
    appVersion?: string | null;
    brandId?: number | null;
    shippingCharges?: number | null;
    isCategoryIconHidden?: boolean;
    isSubCategoryIconHidden?: boolean;
    hideCategory?: boolean;
    isShiprocket?: boolean;
    isScreenshotEnabled?: boolean;
    isBrowseableWithOutLogin?: boolean;
    awsBasePath?: string | null;
    structure_Id?: number | null;
    logoAlignment?: string | null;
}

