import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
// import { Configuration, EcomMobAuthService, EcomBrandwiseApkThemeData } from 'build/openapi';
import { constant } from '../constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Configuration, DynamicHeaderModelGet, EcomBrandwiseApkThemeData, EcomMobAuthService } from 'build/openapi';
import { isPlatformBrowser } from '@angular/common';
export interface UserDetails {
    userImageUrl: string;
    userEmail: string;
    userFullName: string;
    userGender: string;
    userPhoneNo: string;
}
@Injectable({
    providedIn: 'root',
})
export class AccountService {

    public productCardsSizeSource = new BehaviorSubject<any | undefined>(undefined);
    productCardsSize$ = this.productCardsSizeSource.asObservable();
  
    public allPageColorsSource = new BehaviorSubject<any | undefined>(undefined);
    allPageColors$ = this.allPageColorsSource.asObservable();


    public userImage: string = '';
    public userName: string = '';
    public userEmail: string = '';
    public userGender: string = '';
    private userDetails!: UserDetails;
    private ecomUserLogin: boolean = false;
    private _loginListener = new BehaviorSubject<boolean>(false);
    loginListner$ = this._loginListener.asObservable();
    private _userDetails = new BehaviorSubject<UserDetails | undefined>(undefined);
    userDetailsListner$ = this._userDetails.asObservable();
    public _ecomAppSettingDetails = new BehaviorSubject<EcomBrandwiseApkThemeData | undefined>(undefined);
    ecomAppSettingDetailsListner$ = this._ecomAppSettingDetails.asObservable();

    public _headeMenuDesign = new BehaviorSubject<any | undefined>(undefined);
    _headeMenuDesign$ = this._headeMenuDesign.asObservable();

    public _currentUrl = new BehaviorSubject<any>(undefined);
    currentUrlListner$ = this._currentUrl.asObservable();
    public _baseProductId = new BehaviorSubject<any>(undefined)
    baseProductId$ = this._baseProductId.asObservable();
    public _headerStyles = new BehaviorSubject<DynamicHeaderModelGet | undefined>(undefined);
    _headerStyles$ = this._headerStyles.asObservable();

    public _headerBackgroundColor = new BehaviorSubject<any | undefined>(undefined);
    headerBackgroundColor$ = this._headerBackgroundColor.asObservable();

    public _isHomePage = new BehaviorSubject<any | undefined>(undefined);
    isHomePage$ = this._isHomePage.asObservable();

    public _subCategoriesHeight = new BehaviorSubject<any | undefined>(undefined);
    subCategoriesHeight$ = this._subCategoriesHeight.asObservable();


    userPhoneNo!: string;
    today: Date = new Date();
    tokenExpirationTimer: any;
    get isEcomUserLogin() {
        return this.ecomUserLogin;
    }

    constructor(private router: Router, private configuration: Configuration, private ecomMobAuthService: EcomMobAuthService, private snackBar: MatSnackBar,@Inject(PLATFORM_ID) private platformId: Object) {

     }

    autoLogout(expiryDateTime: number): void {
        if (expiryDateTime == new Date().getTime()) {
            this.logout();
            this.snackBar.open('Session Timed Out! Please Login', 'OK', { duration: 3000, panelClass: 'warning' });
        }
    }

    checkLogin() {
        if (isPlatformBrowser(this.platformId)) {
          const accessToken = this.storage?.getItem(constant.authData.access_token);
          
          if (accessToken) {
            try {
              let expiresInNumber = 0;
              const expiresIn = this.storage?.getItem(constant.authData.expires_in);
              expiresInNumber = expiresIn !== null ? +expiresIn! || 0 : 0;
      
              this.autoLogout(expiresInNumber);
      
              const data = this.parseJwt(accessToken);
              this.ecomUserLogin = data.role === constant.roles.ROLE_ECOM_USER;
              this.userName = this.storage?.getItem(constant.mainAccount.account_name) || '';
              this.userGender = this.storage?.getItem(constant.mainAccount.account_gender) || '';
              this.userImage = this.storage?.getItem(constant.mainAccount.photo_path) || "";
      
              if (this.configuration.apiKeys) {
                this.configuration.apiKeys['Bearer'] = `Bearer ${accessToken}`;
              } else {
                console.error('apiKeys is undefined');
              }
      
              this._loginListener.next(true);
              if (this.userDetails) {
                this._userDetails.next(this.userDetails);
              } else {
                this.emitUserDetails();
              }
            } catch (error) {
              this._loginListener.next(false);
              this._userDetails.next(this.userDetails);
            }
          } else {
            this._loginListener.next(false);
            this._userDetails.next(this.userDetails);
          }
        } else {
          this._loginListener.next(false);
          this._userDetails.next(this.userDetails);
        }
      }
      

    // checkLogin() {
    //     if (this.storage.getItem(constant.authData.access_token)) {
    //         try {
    //             const expiresIn = localStorage.getItem(constant.authData.expires_in);
    //             const expiresInNumber = expiresIn !== null ? +expiresIn || 0 : 0;
    //             this.autoLogout(expiresInNumber);

    //             const data = this.parseJwt(this.storage.getItem(constant.authData.access_token) || '');
    //             this.ecomUserLogin = data.role === constant.roles.ROLE_ECOM_USER;
    //             this.userName = this.storage.getItem(constant.mainAccount.account_name) || '';
    //             this.userGender = this.storage.getItem(constant.mainAccount.account_gender) || '';
    //             this.userImage = this.storage.getItem(constant.mainAccount.photo_path) || "";
    //             // this.configuration.apiKeys['Bearer'] = `Bearer ${this.storage.getItem(constant.authData.access_token)}`;
    //             if (this.configuration.apiKeys) {
    //                 this.configuration.apiKeys['Bearer'] = `Bearer ${this.storage.getItem(constant.authData.access_token)}`;
    //             } else {
    //                 // Handle the case where this.configuration.apiKeys is undefined
    //                 console.error('apiKeys is undefined');
    //             }

    //             this._loginListener.next(true);
    //             if (this.userDetails) {
    //                 this._userDetails.next(this.userDetails);
    //             }
    //             else {
    //                 this.emitUserDetails();
    //             }
    //         } catch (error) {
    //             // this.userDetails = undefined;
    //             this._loginListener.next(false);
    //             this._userDetails.next(this.userDetails);
    //         }
    //     }
    //     else {
    //         // this.userDetails = undefined;
    //         this._loginListener.next(false);
    //         this._userDetails.next(this.userDetails);
    //     }
    // }
    public emitImage(base64: string) {
        this.userImage = base64;
        this.userDetails = { userEmail: this.userEmail, userFullName: this.userName, userImageUrl: base64, userGender: this.userGender, userPhoneNo: this.userPhoneNo };
        this._userDetails.next(this.userDetails);
    }
    public emitUserDetails(): void {
        this.ecomMobAuthService.apiEcomMobAuthCurrentUserGet().subscribe(data => {
            if (data.data) {
                this.userImage = data.data[0].dpPath || '';
                this.userEmail = data.data[0].email || '';
                this.userGender = data.data[0].gender || '';
                this.userName = data.data[0].fullName || '';
                this.userPhoneNo = data.data[0].mobile || '';
                this.userDetails = { userEmail: data.data[0].email || '', userFullName: data.data[0].fullName || '', userImageUrl: data.data[0].dpPath || '', userGender: data.data[0].gender || '', userPhoneNo: data.data[0].mobile || '' };
                this._userDetails.next(this.userDetails);
            }
        });

    }

    private parseJwt(token: string): any {
        const base64Url = token.split('.')[1];
        const base64 = decodeURIComponent(atob(base64Url).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        var res = JSON.parse(base64) as UserToken;
        return res;
    };

    private checkUserRole(role: string): boolean {
        let token = this.storage?.getItem(constant.authData.access_token);
        if (!token) {
            return false;
        }
        let roles = this.parseJwt(token).roles;
        return roles && roles.includes(role);
    }


    get storage(): Storage | null {
        if (isPlatformBrowser(this.platformId)) {
          return localStorage;
        } else {
          // Return a mock or null when not in the browser
          return null;
        }
      }
    public logout(): void {
        localStorage.clear();
        sessionStorage.clear();
        this.ecomUserLogin = false;
        this._loginListener.next(false);
        this.router.navigate(['/structure_1/home']);
    }

}
export interface UserDetails {
    userImageUrl: string;
    userEmail: string;
    userFullName: string;
    userGender: string;
    userPhoneNo: string;
}

export interface ErrorMessageModel {
    imgSrc: string;
    errorTitleMessage: string;
    errorSubTitleMessage: string;
}
export class UserToken {
    roles!: string;
}
