/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EcomSettingsModel { 
    brandid?: number;
    appName?: string | null;
    appLogo?: string | null;
    imageFile?: Blob | null;
    extension?: string | null;
    primaryColor?: string | null;
    backgroundColor?: string | null;
    fontFamily?: string | null;
    fontSize?: number;
    isDarkTheme?: boolean;
    hintHightlightColor?: string | null;
    accentColor?: string | null;
    razorkey?: string | null;
    razorPaySecretAccesskey?: string | null;
    shipping_Charge?: number | null;
    textPrimaryColor?: string | null;
    textSecondaryColor?: string | null;
    topicColor?: string | null;
    isCOD?: boolean | null;
}

