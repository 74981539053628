import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Inject, Output, PLATFORM_ID, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BaseProductForBrandModel, EcomBrandwiseApkThemeData, EcomWebAuthService, SelectProductMob } from 'build/openapi';
import { ModelNameByBrandIds } from 'build/openapi/model/modelNameByBrandIds';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription, debounceTime } from 'rxjs';
import { AccountService, UserDetails } from 'src/app/_services/account.service';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', '../../../styles/searchStyle.scss', '../../../styles/dropDownMenuStyles.scss']
})
export class HeaderComponent {
  // @Input('ecomAppDetails') ecomAppDetails: EcomBrandwiseApkThemeData | undefined;
  cartItemsLength: any;
  isLoadingData: boolean = false;
  errorData: any
  isError: boolean = false;
  allHeaderCategories: any = {}
  handleImageError(event: any) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = '../../../../assets/images/default2.jpg';
    // imgElement.style.width = '50px';
  }
  isHomePage: any
  searchString = new FormControl('');
  headerAnimation: any

  isAuthenticated!: boolean;
  userDetails: any;
  baseProductFilterModel: any;
  productsList: BaseProductForBrandModel[] = [];
  errorMessage: string | undefined;
  borderUi: string | undefined;
  subCategories: SelectProductMob[] = [];
  // categories: SelectProductMob[] =[];
  categoriesDDL: any;

  categoriesVisible: boolean = false;
  nonCategoryName: ModelNameByBrandIds = {};
  get appData() {
    return environment;
  }
  trigger = 0;
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger: any;
  // TriggerDropDown = 0;
  // scrolled = false;
  // isAtTop: boolean = true;
  isScrolled: boolean = false;
  headerBackgroundColor: any;
  header: any;
  @HostListener('window:scroll', ['$event'])


  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.allHeaderCategories.solidHeader != "Solid" && this.ishomepageHeader == true && window.innerWidth > 991) {

        if (this.header == 1) {
          this.headerBackgroundColor = this.allHeaderCategories.headerBackgroundColor;
  
          this.accountService._headerBackgroundColor.next(this.headerBackgroundColor)
  
        }
        else {
  
          const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
          this.isScrolled = offset > 50;
          this.headerBackgroundColor = this.isScrolled ? this.allHeaderCategories.headerBackgroundColor : 'transparent';
  
          this.accountService._headerBackgroundColor.next(this.headerBackgroundColor)
        }
      } else {
        this.headerBackgroundColor = this.allHeaderCategories.headerBackgroundColor
        this.accountService._headerBackgroundColor.next(this.headerBackgroundColor)
      }  
    }
  

  }

  loginSubscription: Subscription | undefined;
  @Output() toggle = new EventEmitter();
  allPageColors:any
  private platformId: Object;
  constructor(private router: Router, private accountService: AccountService, private snackBar: MatSnackBar, private ecomWebAuthService: EcomWebAuthService, private ren: Renderer2,@Inject(PLATFORM_ID) platformId: Object) {
    this.platformId = platformId;
    this.accountService.currentUrlListner$.subscribe(res => {
      this.curentUrl = res
      if (this.curentUrl == '/') {
        this.ishomepageHeader = true

      }
      else {
        this.ishomepageHeader = false

      }
    })
    // this.getAllHeaderDesign()
    this.accountService.userDetailsListner$.subscribe(data => {
      if(data){
      this.userDetails = data;

    

      }
    });

   
      if (isPlatformBrowser(this.platformId)) {
        if (window.location.pathname === '/') {
          // Set true value to appselector
          this.isHomePage = true;
    
        } else {
          // Set false or default value to appselector for other pages
          this.isHomePage = false; // or some default value
        }
      }
   




    this.accountService.allPageColors$.subscribe(
      data => {
        this.allPageColors = data;
      
      }
    )
  }
  inputVisible: boolean = false;
  search = 'searchNone'
  showInput() {
    this.search = 'searchNone'
    this.inputVisible = true;
    this.search = 'search'
  }

  hideInput() {
    this.inputVisible = false;
    this.search = 'searchNone'
  }
  ishomepageHeader: boolean = false
  curentUrl: any

  search4empty = 'search4auto';
  search4fill = 'search4autoOpen'
  searchStringClass:any ='search4auto'
  ngOnInit(): void {

    this.accountService.loginListner$.subscribe(data => {
      this.isAuthenticated = data;
    })

    this.getCategoriesAndSubCategories();
    this.searchString.valueChanges.pipe(debounceTime(1000)).subscribe(res => {
      this.getProducts();

      // setTimeout(() => {
      //   this.searchStringClass = 'search4autoOpen'
      // }, 3000);
      // if(this.searchString.value == ''){
      //   this.search4empty;
      // }else {
      //   this.search4fill;
      // }

    })

    this.accountService._headerStyles$.subscribe(res => {
      if (res) {
      
        this.allHeaderCategories = res;

        this.selectedLinksEnd = this.allHeaderCategories.dynamicNavLinkModelGet?.navLinkEnd.split(',')
        this.selectedToppings = this.allHeaderCategories.dynamicNavLinkModelGet?.navLinkName.split(',')


        this.allHeaderCategories.dropDownStyle

          if (isPlatformBrowser(this.platformId)) {
            if ( this.allHeaderCategories.solidHeader == "Solid" || window.innerWidth < 991) {
              this.headerBackgroundColor = this.allHeaderCategories.headerBackgroundColor;
              this.accountService._headerBackgroundColor.next(this.headerBackgroundColor)
            }
            else {
              
              this.headerBackgroundColor = 'transparent'
            }
          }
       
      
        this.getHeaderCategories();
      
      }
    });

    this.accountService.ecomAppSettingDetailsListner$.subscribe(res => {
      if (res) {
      
        this.ecomAppSettings = res;
 
        // this.borderUi = this.border + this.ecomAppSettings?.textColor + this.borderOpacity;

        
        // this.getHeaderCategories();
        // this.setFavicon(this.ecomAppDetails?.appLogo2!);
      }
    });
    this.ecomWebAuthService.apiEcomWebAuthGetNonCategorizedNameGet(this.appData.brandId).subscribe((res) => {
      if (res.data != null) {
        this.nonCategoryName = res.data as ModelNameByBrandIds
      }
      else {
        let data: ModelNameByBrandIds = {
          id: 0,
          brandId: 0,
          modelName: 'OTHERS',
          isDelete: false
        }
        this.nonCategoryName = data

      }
    })
  }

  selectedLinksEnd: [] = [];
  selectedToppings: [] = []


  searchOrClose() {
    if (this.searchString.value) {
      this.searchString.reset();
    }
    else {
      this.getProducts();
    }
  }
  getCategoriesAndSubCategories() {
    this.ecomWebAuthService.apiEcomWebAuthCategoryDDLWithProductPresenceGet(this.appData.brandId).subscribe(res => {
      this.categoriesDDL = res.data as SelectProductMob;
    })
  }

  getProducts() {
    let sortModel = {
      lowToHigh: false,
      highToLow: false,
      discount: false,
      popular: false,
    }
    this.baseProductFilterModel = {
      searchString: this.searchString.value,
      brandId: this.appData.brandId,
      categoryId: 0,
      subCategoryId: 0,
      baseProductId: 0,
      pageIndex: 0,
      pageSize: 100,
      multipleBrandsModel: [],
      multipleRangeModel: [],
      multipleColorsModel: [],
      multipleDiscountModel: [],
      isGlobal: false,
      isvisible: true,
      cityId: 0,
      sortFilterModel: sortModel
    }
    this.ecomWebAuthService.apiEcomWebAuthGetBaseProductsAllPost(this.baseProductFilterModel).subscribe(data => {
      this.productsList = data.data as [];
      this.errorMessage = "Product list is empty !!";
    }, (error: HttpErrorResponse) => {
      this.errorMessage = "Something went wrong !!";
    })
  }
  viewCategoryMenuData(trigger1: any, category: any) {
    trigger1.closeMenu();
    this.router.navigate(['/products'], {
      queryParams: {
        categoryId: btoa(category.value),
        categoryName: btoa(category.name),
      }
    });
  }
  viewSubCategoryMenuData(trigger1: any, trigger2: any, category: any, subCategory: any) {
    trigger1.closeMenu();
    trigger2.closeMenu();
    this.router.navigate(['/products'], {
      queryParams: {
        categoryId: btoa(category.value),
        categoryName: btoa(category.name),
        subCategoryId: btoa(subCategory?.value),
        subCategoryName: btoa(subCategory?.name),
      }
    });
  }

  viewProductDetails(baseProductID: any) {
    // let apkUrl: string = this.appData.domainUrl + '/products/details?baseProductID=' + btoa(baseProductID);
    // window.open(apkUrl, '_blank');
    this.router.navigate(['/products/details'], {
      queryParams: {
        baseProductID: btoa(baseProductID),
      }
    });
  }
  navigateToCart(): void {
    if (this.isAuthenticated) {
        this.router.navigate(['/cart']);
    }
    else {
      this.router.navigate(['/accounts/sign-in']);
      this.snackBar.open('Please login to view shopping cart', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  navigateToWishList(): void {
    if (this.isAuthenticated) {
      this.router.navigate(['wishlist']);
    }
    else {
      this.router.navigate(['/accounts/sign-in']);
      this.snackBar.open('Please login to view wishlist', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  navigateToOrders(): void {
    if (this.isAuthenticated) {
      this.router.navigate(['/user/order/list']);
    }
    else {
      this.router.navigate(['/accounts/sign-in']);
      this.snackBar.open('Please login to view orders list', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  navigateToAddresses(): void {
    if (this.isAuthenticated) {
      this.router.navigate(['/user/manage-address']);
    }
    else {
      this.router.navigate(['/accounts/sign-in']);
      this.snackBar.open('Please login to view manage addresses', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  navigateToEditProfile(): void {
    if (this.isAuthenticated) {
      this.router.navigate(['/user/edit-profile']);
    }
    else {
      this.router.navigate(['/accounts/sign-in']);
      this.snackBar.open('Please login to view edit profile', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  navigateToProfileOverview(): void {
    if (this.isAuthenticated) {
      this.router.navigate(['/user/profile-overview']);
    }
    else {
      this.router.navigate(['/user/profile-overview']);
      this.snackBar.open('Please login to view profile overview', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  logout() {
    this.accountService.logout();
    this.router.navigate(['/']);
  }

  menuEnter1() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave1(trigger: any, button: any) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.isMatMenuOpen = false;
      }
    }, 80)
  }

  menu2enter() {
    this.isMatMenu2Open = true;
  }

  menu2Leave(trigger1: any, trigger2: any, button: any) {
    setTimeout(() => {
      if (this.isMatMenu2Open) {
        trigger1.closeMenu();
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        this.enteredButton = false;
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.isMatMenu2Open = false;
        trigger2.closeMenu();
      }
    }, 100)
  }
  mouseEnter(trigger: MatMenuTrigger) {
    setTimeout(() => {
      if (!trigger.menuOpen) {
        trigger.openMenu();
      }
      this.trigger = 0;
    }, 1);

  }

  mouseLeave(trigger: MatMenuTrigger) {
    setTimeout(() => {
      if (trigger.menuOpen && this.trigger === 0) {
        trigger.closeMenu();
      }
    }, 200);
  }
  menuMouseLeave(trigger: MatMenuTrigger) {
    setTimeout(() => {
      if (trigger.menuOpen) {
        trigger.closeMenu();
      }
      this.trigger = 0;
    }, 1);
  }
  openCategories: string = "close"

  showCategories() {
    this.categoriesVisible = true
    this.openCategories = "open"
    if (this.openCategories = "open") {
      this.headerBackgroundColor = this.allHeaderCategories.headerBackgroundColor;
      this.accountService._headerBackgroundColor.next(this.headerBackgroundColor)
    }
    else {
      this.headerBackgroundColor = this.allHeaderCategories.headerBackgroundColor;  
    }
  }

  hideCategories() {
    this.categoriesVisible = false
    this.openCategories = "close"
    // if (this.openCategories = "close") {
    //   this.headerBackgroundColor = 'transparent'
    // }
    this.headerBackgroundColor = 'transparent';
  
    this.accountService._headerBackgroundColor.next(this.headerBackgroundColor);
  }

  hideCategoriesOnhome() {
    this.categoriesVisible = false
  }

  // categories:any= 0

  otherProducts(category: any) {

    this.router.navigate(['/products'], {
      queryParams: {
        categoryMasterId: btoa(this.categoriesDDL[0].categoryMasterId),
        categoryId: btoa(this.categoriesDDL[0].value),
        categoryName: btoa(this.categoriesDDL[0].name),
        subCategoryId: btoa(this.categoriesDDL[0]?.subCategory.value),
        subCategoryName: btoa(this.categoriesDDL[0]?.subCategory.name),
        noneCategory: category === 'none-categories' ? category : 'categories'
      }
    });
  }

  navRouting(link: any) {
    if (link == 'Home') {
      this.router.navigate(['/'])
    } else if (link == 'Category') {
      this.router.navigate(['/products'])
    }
    else if (link == 'NonCategory') {
      this.Products('NonCategory')
    }
  }

  Products(category: any) {
    // this.router.navigate(['/products'], {
      let queryParams: any = {
        categoryMasterId: btoa(this.categoriesDDL[0].categoryMasterId),
        categoryId: btoa(this.categoriesDDL[0].value),
        categoryName: btoa(this.categoriesDDL[0].name),
        subCategoryId: btoa(this.categoriesDDL[0]?.subCategory.value),
        subCategoryName: btoa(this.categoriesDDL[0]?.subCategory.name),
      };
    
      // Check if category is 'NonCategory' and set the 'noneCategory' query parameter accordingly
      if (category === 'NonCategory') {
        queryParams.noneCategory = 'none-categories';
      } else {
        queryParams.noneCategory = 'categories';
      }
    
      this.router.navigate(['/products'], { queryParams });
    // });
  }

  productCategories: SelectProductMob[] = [];
  productSubCategories: SelectProductMob[] = [];
  ecomAppSettings!: EcomBrandwiseApkThemeData;
  // borderUi!: string;
  categories!: any[];
  get blobStoragePath(): any {
    return this.ecomAppSettings.awsBasePath;
  }

  get brandId(): number {
    return environment.brandId;
  }
  get border(): string {
    return environment.border;
  }
  get borderOpacity(): string {
    return environment.borderOpacity;
  }
  customOptions!: OwlOptions;

  private setFavicon(iconPath: string) {
    const link: HTMLLinkElement = document.querySelector('link[rel="icon"]') || document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.href = iconPath;
    document.head.appendChild(link);
  }

  getHeaderCategories() {
    this.ecomWebAuthService.apiEcomWebAuthEcomProductCategoryBrandwiseMasterDDLGet(this.brandId).subscribe(res => {
      if (res.data) {
        this.categories = res.data;
        this.getAllSubCategories(this.categories[0].categoryId)
        if (this.categories.length == 1) {
          this.ecomWebAuthService.apiEcomWebAuthEcomProductSubCategoryMasterBrandwiseDDLGet(res.data[0].value, this.brandId).subscribe(res => {
            this.productCategories = res.data as SelectProductMob[];

            // const newCategory: SelectProductMob = {
            //   name: "OTHERS",
            //   value: 0,
            //   path: '../../../../../assets/images/others.jpg',
            //   isVisible: null,
            //   subCategoryCount: 0,
            //   categoryId: 112,
            //   categoryMasterId: 1,
            //   categoryName: 'aaaa',
            //   brandList: null,
            //   productCount: 0,
            //   subCategory: null,
            // };
            // this.productCategories.push(newCategory);

          })
        }
        else {

          this.productCategories = res.data as SelectProductMob[];
          // const newCategory: SelectProductMob = {
          //   name: "OTHERS",
          //   value: 0,
          //   path: '../../../../../assets/images/others.jpg',
          //   isVisible: null,
          //   subCategoryCount: 0,
          //   categoryId: 112,
          //   categoryMasterId: 1,
          //   categoryName: 'aaaa',
          //   brandList: null,
          //   productCount: 0,
          //   subCategory: null,
          // };
          // this.productCategories.push(newCategory);
        }
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 401) {
        this.accountService.logout();
      }
    });
  }

  viewAllCategories() {
    if (this.categories.length == 1) {
      this.router.navigate(['/products/sub-categories'], {
        queryParams: {
          categoryId: btoa(this.categories[0]?.value),
          categoryName: btoa(this.categories[0]?.name),
        }
      });
    }
    else {
      this.router.navigate(['/products/categories']);
    }
  }
  viewProductSubCategories(category: any): void {

    if (this.categories.length == 1 || category === 'none-categories') {

      this.router.navigate(['/products'], {
        queryParams: {
          categoryMasterId: btoa(this.categories[0].categoryMasterId.toString()),
          categoryId: btoa(this.categories[0].value.toString()),
          categoryName: btoa(this.categories[0].name.toString()),
          subCategoryId: btoa(category?.value),
          subCategoryName: btoa(category?.name),
          noneCategory: category === 'none-categories' ? category : 'categories'
        }
      });
    }
    else if (category === 'none-categories') {
      this.router.navigate(['/products'], {
        queryParams: {
          categoryMasterId: btoa(this.categories[0].categoryMasterId),
          categoryId: btoa(this.categories[0].value),
          categoryName: btoa(this.categories[0].name),
          subCategoryId: btoa(category?.subcategoryMasterId),
          subCategoryName: btoa(category?.name),
          noneCategory: category === 'none-categories' ? category : 'categories'
        }
      });
    }
    else {
      this.router.navigate(['/products/sub-categories'], {
        queryParams: {
          categoryId: btoa(category?.value),
          categoryName: btoa(category?.name),
        }
      });
    }
  }

  getCategories(data: any[]) {
    return data.slice(0, 4)
  }

  getCat() {

  }

  getAllSubCategories(item: any) {
    this.isLoadingData = true;

    this.ecomWebAuthService.apiEcomWebAuthEcomProductSubCategoryMasterBrandwiseDDLGet(item, this.brandId).subscribe(res => {

      if (res.data) {
        this.isLoadingData = false;
        this.productSubCategories = res.data;


        if (this.productSubCategories.length == 0) {
          this.isLoadingData = false;
          this.isError = true;
          this.errorData = {
            imgSrc: "../../../../../assets/images/error-pages/wish-list.png",
            errorTitleMessage: "Sub categories list is empty !!",
            errorSubTitleMessage: "Explore more and shortlist some items.",
          }
        }
        else {
          this.isError = false;
        }
      }
      else {
        // this.isError = true;
        // this.isLoadingData = false;
        // this.errorData = {
        //   imgSrc: "../../../../../assets/images/error-pages/error.png",
        //   errorTitleMessage: "Something went wrong",
        //   errorSubTitleMessage: "Please try after sometime!",
        // }
      }
    }, (error: HttpErrorResponse) => {
      // this.isLoadingData = false;
      // this.isError = true;
      if (error.status == 0) {
        if (navigator.onLine) {
          // this.errorData = {
          //   imgSrc: "../../../../../assets/images/error-pages/error.png",
          //   errorTitleMessage: "Something went wrong",
          //   errorSubTitleMessage: "Please try after sometime!",
          // }
        }
        else {
          // this.errorData = {
          //   imgSrc: "../../../../../assets/images/error-pages/no-signal.png",
          //   errorTitleMessage: "Internet connection lost",
          //   errorSubTitleMessage: "Please check your internet connection!",
          // }
        }
      }
      else if (error.status == 401) {
        this.accountService.logout();
      }
      else {
        // this.errorData = {
        //   imgSrc: "../../../../../assets/images/error-pages/error.png",
        //   errorTitleMessage: "Something went wrong",
        //   errorSubTitleMessage: "Please try after sometime!",
        // }
      }
    })
  }
  viewProducts(subCategory: any, noneCategory: any): void {
    this.router.navigate(['/products'], {
      queryParams: {
        categoryId: btoa(subCategory.categoryId),
        categoryName: btoa(subCategory.categoryName),
        subCategoryId: btoa(subCategory?.value),
        categoryMasterId: btoa(subCategory.categoryMasterId),
        // subCategoryId: btoa(subCategory?.subcategoryMasterId),
        subCategoryName: btoa(subCategory?.name),
        noneCategory: noneCategory === 'none-categories' ? noneCategory : 'categories'
      }
    });
  }

  // showCategories() {
  //   this.categoriesVisible = true
  //   this.openCategories = "open"
  // }

}
