/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InlineObject { 
    FK_Order_Id?: number;
    Shipment_ID?: number;
    FK_OrderItem_Id?: number;
    FK_Product_Id?: number;
    FK_AppUser_Id?: number;
    Shipment_Status?: string;
    Created_Date?: string;
    FullName?: string;
    Shipping_Address?: string;
    Email_Address?: string;
    PhoneNos?: string;
    City?: string;
    PostCode?: string;
}

