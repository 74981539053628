/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EcommerceCategoryModel } from './ecommerceCategoryModel';


export interface EcommerceTopicsModel { 
    mobileAspectRatio?: string | null;
    topicFontType?: string | null;
    topicFontSize?: string | null;
    withPrice?: boolean | null;
    buyNow?: boolean | null;
    showDiscount?: boolean | null;
    addToCart?: boolean | null;
    titleAlignment?: string | null;
    alignment?: string | null;
    isSticky?: boolean | null;
    isSquare?: boolean | null;
    isProduct?: boolean | null;
    topicColor?: string | null;
    topicTextColor?: string | null;
    topic_Id?: number;
    topic_Name?: string | null;
    topic_Description?: string | null;
    topic_CategoryYN?: boolean;
    addyn?: boolean | null;
    slider?: boolean | null;
    newsBarContent?: string | null;
    isNewArrival?: boolean | null;
    priority?: number | null;
    isFrameRequired?: boolean | null;
    aspectRatio?: string | null;
    advertisementType?: string | null;
    isParagraph?: boolean | null;
    paragraphContent?: string | null;
    paragraphId?: number | null;
    mobileImage?: string | null;
    imageUrl?: string | null;
    productUrl?: string | null;
    imageContent?: string | null;
    isNewsBar?: boolean | null;
    isParagraphImage?: boolean | null;
    imageAlignment?: string | null;
    imageButtonName?: string | null;
    category_id?: number | null;
    sub_category_id?: number | null;
    button_Background_Color?: string | null;
    button_Name?: string | null;
    button_Color?: string | null;
    isCustomButton?: boolean | null;
    buttonType?: string | null;
    borderRadius?: string | null;
    topPadding?: string | null;
    bottomPadding?: string | null;
    leftPadding?: string | null;
    rightPadding?: string | null;
    bannerContentFontSize?: string | null;
    bannerContentFontWeight?: string | null;
    bannerContentTitleFontSize?: string | null;
    bannerContentTitleFontWeight?: string | null;
    bannerContentTitleColor?: string | null;
    bannerContentColor?: string | null;
    productAnimation?: string | null;
    productInRow?: string | null;
    bannerContent3DStyle?: string | null;
    bannerContentTitleFontStyle?: string | null;
    bannerContentTitle3DStyle?: string | null;
    bannerContentFontStyle?: string | null;
    imageContentAlignment?: string | null;
    imageContentTitle?: string | null;
    productCardHeight?: string | null;
    productCardImageType?: string | null;
    bannerContentButtonFontSize?: string | null;
    productCardBorder?: boolean;
    names?: Array<EcommerceCategoryModel> | null;
}

