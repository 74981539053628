/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InlineObject1 { 
    fullName: string;
    mobile: string;
    mobileCountryCode?: string;
    email?: string;
    password: string;
    role?: string;
    roles?: Array<string>;
    Level_Type_ID?: number;
    brandid?: number;
    filename?: Blob;
    extension?: string;
}

