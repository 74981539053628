/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ColorModel } from './colorModel';
import { SizeModel } from './sizeModel';
import { ReviewsModel } from './reviewsModel';
import { RatingsModel } from './ratingsModel';
import { SPMRPModel } from './sPMRPModel';
import { ProductImagePaths } from './productImagePaths';


export interface BaseProductForBrandModel { 
    productWeight?: string | null;
    hsnCode?: string | null;
    noOfReviews?: number;
    reviewsModel?: Array<ReviewsModel> | null;
    ratingsModel?: RatingsModel;
    averageRatings?: number | null;
    cityId?: number;
    isVisible?: boolean | null;
    isDeleted?: boolean | null;
    baseProductId?: number | null;
    baseProductName?: string | null;
    productImagePath?: Array<ProductImagePaths> | null;
    spMrp?: SPMRPModel;
    categoryId?: number | null;
    subCategoryId?: number | null;
    categoryName?: string | null;
    subCategoryName?: string | null;
    categoryMasterId?: number | null;
    subCategoryMasterId?: number | null;
    outletId?: number | null;
    userId?: number | null;
    discountPercentage?: number | null;
    isFavorite?: number | null;
    link?: string | null;
    sizeModel?: Array<SizeModel> | null;
    colorModel?: Array<ColorModel> | null;
    metaDescription?: string | null;
    snippetPreview?: string | null;
    focusedkeyword?: string | null;
    productMetaTitle?: string | null;
}

