<mat-toolbar  [ngClass]="{ 'fixed-header': isScrolled, 'scrolled-header': isScrolled }" [ngStyle]="{ 'height':allHeaderCategories.dropDownStyle == '3' ?  'auto' : allHeaderCategories.headerHight + 'px', 'position':'fixed', 
'top':allHeaderCategories.dualHeader == true ? '40px' : '0px', 'left':'0', 'z-index':'1000' }" class="p-0 m-0 w-100"
    [style.background-color]="headerBackgroundColor" style="display: flex;flex-direction: column;">

    <div class="d-flex d-none d-sm-none d-lg-flex d-md-none justify-content-between w-100 align-items-center pe-3 ps-2"
        [ngStyle]="{'height':allHeaderCategories.headerHight + 'px'}">

        <div class="" *ngIf="allHeaderCategories.logoAlignment == 'Right'"
            style="display: flex; align-items: center;justify-content: start;width: 33%;">

            <span class="me-3 link" *ngFor="let link of selectedLinksEnd"
                [ngStyle]="{'font-size':allHeaderCategories.fontHeight + 'px','font-weight':allHeaderCategories.fontWeight,'color':allHeaderCategories.headerTextColor}"
                [ngClass]="allHeaderCategories.fontStyle" style="text-decoration: none;">

                <span *ngIf="allHeaderCategories.navLinksEndIcon == false">{{link}}</span>
                <i class="bi bi-cart-fill " *ngIf="allHeaderCategories.navLinksEndIcon == true && link == 'Cart'"></i>
                <i class="bi bi-heart-fill" *ngIf="allHeaderCategories.navLinksEndIcon && link == 'WishList'"></i>
                <div *ngIf="allHeaderCategories.navLinksEndIcon && link == 'Profile' &&  !isAuthenticated">
                    <i class="bi bi-person-fill" (mouseenter)="mouseEnter(profileTrigger)"
                        (mouseleave)="mouseLeave(profileTrigger)" #profileTrigger="matMenuTrigger"
                        [matMenuTriggerFor]="profileSubMenu" (error)="handleImageError($event)"></i>

                    <mat-menu class="p-3" #profileSubMenu="matMenu" [hasBackdrop]="false" xPosition="after"
                        [ngStyle]="{'background-color': allHeaderCategories.headerBackgroundColor , 'color': allHeaderCategories.headerTextColor}">
                        <span class="p-2" (mouseleave)="menuMouseLeave(profileTrigger)" (mouseenter)="trigger = 1"
                            style="background-color: black !important;" class=" mat-elevation-z1"
                            [ngClass]="allHeaderCategories.fontStyle">
                            <div class="row m-0">
                                <div *ngIf="!isAuthenticated">
                                    <span class="fs-md fw-500">Welcome</span>
                                </div>
                                <div *ngIf="!isAuthenticated">
                                    <span class="fs-x-sm fw-500">To access and manage orders</span>
                                </div>
                                <!-- <div class="link d-flex justify-content-between align-items-center me-2"
                            [routerLink]="['/user/profile']" routerLinkActive="router-link-active"
                            *ngIf="isAuthenticated">
                            <img *ngIf="userDetails?.userImageUrl"
                                [src]="" alt="profile-img"
                                class="profile-img me-2"
                                [ngStyle]="{'border': allHeaderCategories.headerTextColor+appData.borderOpacityFifty}">
                            <div *ngIf="!userDetails?.userImageUrl"
                                class="profile-img d-flex justify-content-center align-items-center"
                                [ngStyle]="{'border': appData.border+allHeaderCategories.headerTextColor+appData.borderOpacityFifty}">
                                <span class="t-tr-u fw-500">{{userDetails?.userFullName |
                                    slice:0:1}}</span>
                            </div>
                            <div class="">
                                <span class="fs-sm fw-500 t-tr-c text-ellipsis">
                                    {{userDetails?.userFullName}}
                                </span><br>
                                <span class="fs-x-sm fw-500 text-ellipsis">
                                    {{ userDetails?.userEmail}}
                                </span>
                            </div>
                        </div> -->
                            </div>
                            <div class="mb-2" *ngIf="!isAuthenticated">
                                <button mat-stroked-button aria-label="login-route-button"
                                    [routerLink]="['/accounts/sign-in']" routerLinkActive="router-link-active"
                                    [ngStyle]="{'color':allPageColors.buttonTextColor,'background-color':allPageColors.buttonBackgroundColor }">LOGIN
                                    / SIGNUP</button>
                            </div>
                            <mat-divider style="border-top: 1px solid black;"></mat-divider>
                            <div>
                                <span class=" fs-sm submenu-links link" (click)="navigateToOrders()">Orders</span>
                            </div>
                            <div><span class="fs-sm submenu-links link" (click)="navigateToWishList()">Wishlist</span>
                            </div>
                            <div class="mb-1"><span class="fs-sm submenu-links link" (click)="navigateToCart()">Shopping
                                    Cart</span>
                            </div>

                            <mat-divider style="border-top: 1px solid black;"></mat-divider>
                            <div><span class="fs-sm submenu-links link" (click)="navigateToAddresses()">Saved
                                    Addresses</span></div>
                            <div> <span class="fs-sm submenu-links link " (click)="navigateToEditProfile()">Edit
                                    Profile</span></div>
                            <div><span class="fs-sm submenu-links link" (click)="logout()"
                                    *ngIf="isAuthenticated">Logout</span></div>
                        </span>
                    </mat-menu>

                </div>

                <div class="me-3" *ngIf="allHeaderCategories.navLinksEndIcon && link == 'Profile' &&  isAuthenticated">
                    <img style="width: 30px; height: 30px; border-radius: 50%; cursor: pointer;"
                        (mouseenter)="mouseEnter(profileTrigger)" (mouseleave)="mouseLeave(profileTrigger)"
                        #profileTrigger="matMenuTrigger" [matMenuTriggerFor]="profileSubMenu" class="border" [src]=" userDetails ?ecomAppSettings.awsBasePath+userDetails.userImageUrl : '../../../../assets/images/default.jpg' "
                        (error)="handleImageError($event)" />
                    <mat-menu class="p-3" #profileSubMenu="matMenu" [hasBackdrop]="false" xPosition="after"
                        [ngStyle]="{'background-color': allHeaderCategories.headerBackgroundColor , 'color': allHeaderCategories.headerTextColor}">
                        <span class="p-2" (mouseleave)="menuMouseLeave(profileTrigger)" (mouseenter)="trigger = 1"
                            style="background-color: black !important;" class=" mat-elevation-z1"
                            [ngClass]="allHeaderCategories.fontStyle">
                            <div class="row m-0">
                                <div *ngIf="!isAuthenticated">
                                    <span class="fs-md fw-500">Welcome</span>
                                </div>
                                <div *ngIf="!isAuthenticated">
                                    <span class="fs-x-sm fw-500">To access and manage orders</span>
                                </div>
                                <div class="link d-flex justify-content-between align-items-center me-2"
                                    [routerLink]="['/user/profile']" routerLinkActive="router-link-active"
                                    *ngIf="isAuthenticated">
                                    <img [src]=" userDetails ?ecomAppSettings.awsBasePath+userDetails.userImageUrl : '../../../../assets/images/default.jpg' " alt="profile-img"
                                        class="profile-img m-2">
                                    <div *ngIf="!userDetails?.userImageUrl"
                                        class="profile-img d-flex justify-content-center align-items-center"
                                        [ngStyle]="{'border': appData.border+allHeaderCategories.headerTextColor+appData.borderOpacityFifty}">
                                        <span class="t-tr-u fw-500">{{userDetails?.userFullName |
                                            slice:0:1}}</span>
                                    </div>
                                    <div class="">
                                        <span class="fs-sm fw-500 t-tr-c text-ellipsis">
                                            {{userDetails?.userFullName}}
                                        </span><br>
                                        <span class="fs-x-sm fw-500 text-ellipsis">
                                            {{ userDetails?.userEmail}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2" *ngIf="!isAuthenticated">
                                <button mat-stroked-button aria-label="login-route-button"
                                    [routerLink]="['/accounts/sign-in']" routerLinkActive="router-link-active"
                                    [ngStyle]="{'color':allPageColors.buttonTextColor,'background-color':allPageColors.buttonBackgroundColor }">LOGIN
                                    / SIGNUP</button>
                            </div>
                            <mat-divider style="border-top: 1px solid black;"></mat-divider>
                            <div>
                                <span class=" fs-sm submenu-links link" (click)="navigateToOrders()">Orders</span>
                            </div>
                            <div><span class="fs-sm submenu-links link" (click)="navigateToWishList()">Wishlist</span>
                            </div>
                            <div class="mb-1"><span class="fs-sm submenu-links link" (click)="navigateToCart()">Shopping
                                    Cart</span>
                            </div>

                            <mat-divider style="border-top: 1px solid black;"></mat-divider>
                            <div><span class="fs-sm submenu-links link" (click)="navigateToAddresses()">Saved
                                    Addresses</span></div>
                            <div> <span class="fs-sm submenu-links link " (click)="navigateToEditProfile()">Edit
                                    Profile</span></div>
                            <div><span class="fs-sm submenu-links link" (click)="logout()"
                                    *ngIf="isAuthenticated">Logout</span></div>
                        </span>
                    </mat-menu>
                </div>

            </span>

            <div *ngIf="allHeaderCategories.searchType === '1'" class="custom-search-bar me-2">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search..." placeholder="Search..."
                        [formControl]="searchString" [matAutocomplete]="auto" autocomplete="off" #input
                        aria-label="input search for products" aria-label="input search for products">
                    <mat-autocomplete #auto="matAutocomplete" class="mat-elevation-z1">
                        <mat-option *ngFor="let item of productsList" [ngClass]="allHeaderCategories?.fontFamily"
                            class="fw-500" (click)="viewProductDetails(item?.baseProductId)"
                            [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                            <mat-icon
                                [ngStyle]="{'color': allHeaderCategories?.textColor+appData.borderOpacityFifty}">shopping_bag
                            </mat-icon><span>{{item.baseProductName}}</span>
                        </mat-option>
                        <mat-option *ngIf="productsList?.length == 0" [ngClass]="allHeaderCategories?.fontFamily"
                            class="fw-500"
                            [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                            No Products Found </mat-option>
                    </mat-autocomplete>
                    <div class="input-group-append">
                        <i class="bi bi-search" *ngIf="!inputVisible"
                            [ngStyle]="{'color':allHeaderCategories?.headerTextColor}"></i>
                    </div>
                </div>

            </div>

            <div *ngIf="allHeaderCategories.searchType === '2'" class="search-box me-2">
                <button class="btn-search">
                    <i class="bi bi-search" [ngStyle]="{'color':allHeaderCategories.headerTextColor}"></i>
                </button>
                <input type="text" class="input-search" placeholder="Type to Search..." placeholder="Search..."
                    [formControl]="searchString" [matAutocomplete]="auto" autocomplete="off" #input
                    aria-label="input search for products" aria-label="input search for products">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let item of productsList" [ngClass]="allHeaderCategories?.fontFamily"
                        class="fw-500" (click)="viewProductDetails(item?.baseProductId)"
                        [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                        <mat-icon
                            [ngStyle]="{'color': allHeaderCategories?.textColor+appData.borderOpacityFifty}">shopping_bag
                        </mat-icon><span>{{item.baseProductName}}</span>
                    </mat-option>
                    <mat-option *ngIf="productsList?.length == 0" [ngClass]="allHeaderCategories?.fontFamily"
                        class="fw-500"
                        [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                        No Products Found </mat-option>
                </mat-autocomplete>
            </div>

            <div *ngIf="allHeaderCategories.searchType === '3'" class="me-2">
                <div class="search-box3" [ngStyle]="{'border-color':allHeaderCategories.headerTextColor}">
                    <input type="text" class="search-input" placeholder="Search.." placeholder="Search..."
                        [formControl]="searchString" [matAutocomplete]="auto" autocomplete="off" #input
                        aria-label="input search for products" aria-label="input search for products">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let item of productsList" [ngClass]="allHeaderCategories?.fontFamily"
                            class="fw-500" (click)="viewProductDetails(item?.baseProductId)"
                            [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                            <mat-icon
                                [ngStyle]="{'color': allHeaderCategories?.textColor+appData.borderOpacityFifty}">shopping_bag
                            </mat-icon><span>{{item.baseProductName}}</span>
                        </mat-option>
                        <mat-option *ngIf="productsList?.length == 0" [ngClass]="allHeaderCategories?.fontFamily"
                            class="fw-500"
                            [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                            No Products Found </mat-option>
                    </mat-autocomplete>
                    <button class="search-button3">
                        <i class="bi bi-search" [ngStyle]="{'color':allHeaderCategories.headerTextColor}"></i>
                    </button>
                </div>
            </div>

            <div *ngIf="allHeaderCategories.searchType === '4'">
                <div class="flexbox8">
                    <div class="search8">
                        <div>
                            <input class="searchinput8" type="text" placeholder="Search . . ." required
                                placeholder="Search..." [formControl]="searchString" [matAutocomplete]="auto"
                                autocomplete="off" #input aria-label="input search for products"
                                aria-label="input search for products">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let item of productsList"
                                    [ngClass]="allHeaderCategories?.fontFamily" class="fw-500"
                                    (click)="viewProductDetails(item?.baseProductId)"
                                    [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                                    <mat-icon
                                        [ngStyle]="{'color': allHeaderCategories?.textColor+appData.borderOpacityFifty}">shopping_bag
                                    </mat-icon><span>{{item.baseProductName}}</span>
                                </mat-option>
                                <mat-option *ngIf="productsList?.length == 0"
                                    [ngClass]="allHeaderCategories?.fontFamily" class="fw-500"
                                    [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                                    No Products Found </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="allHeaderCategories.searchType === '5'" class="me-2">
                <div id="container6">
                    <div class="search6">
                        <input type="text" placeholder="Search Here!" name="search" class="searchbar6"
                            [ngStyle]="{'color':allHeaderCategories.headerTextColor}" [formControl]="searchString"
                            [matAutocomplete]="auto" autocomplete="off" #input aria-label="input search for products"
                            aria-label="input search for products">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let item of productsList" [ngClass]="allHeaderCategories?.fontFamily"
                                class="fw-500" (click)="viewProductDetails(item?.baseProductId)"
                                [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                                <mat-icon
                                    [ngStyle]="{'color': allHeaderCategories?.textColor+appData.borderOpacityFifty}">shopping_bag
                                </mat-icon><span>{{item.baseProductName}}</span>
                            </mat-option>
                            <mat-option *ngIf="productsList?.length == 0" [ngClass]="allHeaderCategories?.fontFamily"
                                class="fw-500"
                                [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                                No Products Found </mat-option>
                        </mat-autocomplete>
                        <button type="submit" class="search_button6">
                            <i class="bi bi-search" style="color: black;"></i></button>
                    </div>
                </div>
            </div>

            <div *ngIf="allHeaderCategories.searchType === '6'">
                <div class="search-container9" [ngStyle]="{'color':allHeaderCategories.headerTextColor}">
                    <input class="search-input9" type="text" placeholder="Search"
                        [ngStyle]="{'color':allHeaderCategories.headerTextColor}" [formControl]="searchString"
                        [matAutocomplete]="auto" autocomplete="off" #input aria-label="input search for products"
                        aria-label="input search for products">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let item of productsList" [ngClass]="allHeaderCategories?.fontFamily"
                            class="fw-500" (click)="viewProductDetails(item?.baseProductId)"
                            [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                            <mat-icon
                                [ngStyle]="{'color': allHeaderCategories?.textColor+appData.borderOpacityFifty}">shopping_bag
                            </mat-icon><span>{{item.baseProductName}}</span>
                        </mat-option>
                        <mat-option *ngIf="productsList?.length == 0" [ngClass]="allHeaderCategories?.fontFamily"
                            class="fw-500"
                            [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                            No Products Found </mat-option>
                    </mat-autocomplete>
                </div>
            </div>

        </div>

        <div style="width: 33%;" *ngIf="allHeaderCategories.logoAlignment == 'Left'">
            <img [src]=" headerBackgroundColor == 'transparent' ? allHeaderCategories.weblogo : allHeaderCategories.moblogo "
                class="logo link" alt="app logo" [routerLink]="['/']"
                [ngStyle]="{'width': allHeaderCategories.logoWidth != '0' ? allHeaderCategories.logoWidth +'px' : '100px','height':allHeaderCategories.logoHeight != '0' ? allHeaderCategories.logoHeight + 'px' : ''}"
                style='max-width: 130px' (error)="handleImageError($event)">
        </div>

        <div *ngIf="allHeaderCategories.logoAlignment == 'Left' ||  allHeaderCategories.logoAlignment == 'Right'"
            [ngStyle]="{'color':allHeaderCategories.headerTextColor}" [ngClass]="allHeaderCategories.fontStyle"
            style="display: flex; justify-content: center; align-items: center; width: 33%;">
            <span class="first-menu menu d-flex flex-row gap-3 ms-3"
                *ngIf="allHeaderCategories.logoAlignment == 'Left' ||  allHeaderCategories.logoAlignment == 'Right'">
                <span *ngFor="let link of selectedToppings" class="text-d-n t-tr-u link ">
                    <span *ngIf="link != 'Category'"
                        [ngStyle]="{'font-size':allHeaderCategories.fontHeight + 'px','font-weight':allHeaderCategories.fontWeight, 'color':allHeaderCategories.headerTextColor}"
                        [ngClass]="allHeaderCategories.fontStyle"
                        (click)=" link == 'Products' ? Products('categories') : navRouting(link)">{{link}}
                    </span>

                    <div *ngIf="link == 'Category' && allHeaderCategories.dropDownStyle == '1'" class="menu2"
                        [ngStyle]="{'font-size':allHeaderCategories.fontHeight + 'px','font-weight':allHeaderCategories.fontWeight}">
                        <button class="toggle" style="padding: 3px;">{{link}}</button>
                        <ul class="list2 pe-2 ps-2" [ngStyle]="{'background-color':headerBackgroundColor}">
                            <li class="list-item2" [ngClass]="allHeaderCategories.fontStyle"
                                *ngFor="let Category of  categories" style="--delay:0.2s">
                                {{Category.name}}</li>
                        </ul>
                    </div>

                    <span *ngIf="link == 'Category' && allHeaderCategories.dropDownStyle == '2' ">
                        <span mat-button [matMenuTriggerFor]="animals" class="p-0 m-0"
                            [ngStyle]="{'font-size':allHeaderCategories.fontHeight + 'px','font-weight':allHeaderCategories.fontWeight}"
                            [ngClass]="allHeaderCategories.fontStyle">{{link}}
                        </span>
                        <mat-menu #animals="matMenu" style="height: 400px !important;">
                            <button mat-menu-item [matMenuTriggerFor]="vertebrates" *ngFor="let Category of  categories"
                                (mouseenter)="getAllSubCategories(Category.categoryId)">{{Category.name}}</button>
                        </mat-menu>

                        <mat-menu #vertebrates="matMenu">
                            <button mat-menu-item *ngFor="let subCategory of productSubCategories"
                                (click)="viewProducts(subCategory,'categories')">{{subCategory.name}}</button>
                        </mat-menu>
                    </span>

                    <span *ngIf="link == 'Category' && allHeaderCategories.dropDownStyle == '4'"
                        (click)="showCategories()" [ngClass]="allHeaderCategories.fontStyle"
                        [ngStyle]="{'font-size':allHeaderCategories.fontHeight + 'px','font-weight':allHeaderCategories.fontWeight, 'color':allHeaderCategories.headerTextColor}">
                        {{link}}
                    </span>
                </span>
            </span>

        </div>

        <div *ngIf="allHeaderCategories.logoAlignment == 'Center' "
            [ngStyle]="{'color':allHeaderCategories.headerTextColor}" [ngClass]="allHeaderCategories.fontStyle"
            style="display: flex; justify-content: center ;align-items: center; width: 33%;">

            <span class="first-menu menu d-flex flex-row gap-3 ms-3"
                *ngIf="allHeaderCategories.logoAlignment == 'Center' ">
                <span *ngFor="let link of selectedToppings" class="text-d-n t-tr-u link ">
                    <span *ngIf="link != 'Category'"
                        [ngStyle]="{'font-size':allHeaderCategories.fontHeight + 'px','font-weight':allHeaderCategories.fontWeight, 'color':allHeaderCategories.headerTextColor}"
                        [ngClass]="allHeaderCategories.fontStyle"
                        (click)=" link == 'Products' ? Products('categories') : navRouting(link)">{{link}}
                    </span>

                    <div *ngIf="link == 'Category' && allHeaderCategories.dropDownStyle == '1'" class="menu2"
                        [ngStyle]="{'font-size':allHeaderCategories.fontHeight + 'px','font-weight':allHeaderCategories.fontWeight}">
                        <button class="toggle" style="padding: 3px;">{{link}}</button>
                        <ul class="list2 pe-2 ps-2" [ngStyle]="{'background-color':headerBackgroundColor}">
                            <li class="list-item2" [ngClass]="allHeaderCategories.fontStyle"
                                *ngFor="let Category of  categories" style="--delay:0.2s">
                                {{Category.name}}</li>
                        </ul>
                    </div>

                    <span *ngIf="link == 'Category' && allHeaderCategories.dropDownStyle == '2' ">
                        <span mat-button [matMenuTriggerFor]="animals" class="p-0 m-0"
                            [ngStyle]="{'font-size':allHeaderCategories.fontHeight + 'px','font-weight':allHeaderCategories.fontWeight}"
                            [ngClass]="allHeaderCategories.fontStyle">{{link}}
                        </span>
                        <mat-menu #animals="matMenu" style="height: 400px !important;">
                            <button mat-menu-item [matMenuTriggerFor]="vertebrates" *ngFor="let Category of  categories"
                                (mouseenter)="getAllSubCategories(Category.categoryId)">{{Category.name}}</button>
                        </mat-menu>

                        <mat-menu #vertebrates="matMenu">
                            <button mat-menu-item *ngFor="let subCategory of productSubCategories"
                                (click)="viewProducts(subCategory,'categories')">{{subCategory.name}}</button>
                        </mat-menu>
                    </span>

                    <span *ngIf="link == 'Category' && allHeaderCategories.dropDownStyle == '4'"
                        (click)="showCategories()" [ngClass]="allHeaderCategories.fontStyle"
                        [ngStyle]="{'font-size':allHeaderCategories.fontHeight + 'px','font-weight':allHeaderCategories.fontWeight, 'color':allHeaderCategories.headerTextColor}">
                        {{link}}
                    </span>
                </span>
            </span>

        </div>

        <div style="width: 33%;" class="d-flex justify-content-center align-items-center " *ngIf="allHeaderCategories.logoAlignment == 'Center' ">
            <img [src]=" headerBackgroundColor == 'transparent' ? allHeaderCategories.weblogo : allHeaderCategories.moblogo "
                class="logo link" alt="app logo" [routerLink]="['/']"
                [ngStyle]="{'width': allHeaderCategories.logoWidth != '0' ? allHeaderCategories.logoWidth +'px' : '100px','height':allHeaderCategories.logoHeight != '0' ? allHeaderCategories.logoHeight : ''}"
                style="max-width: 130px;" (error)="handleImageError($event)">
        </div>

        <div *ngIf="allHeaderCategories.logoAlignment == 'Left' || allHeaderCategories.logoAlignment == 'Center'"
            style="display: flex; align-items: center;justify-content: end; width: 33%;">

            <div *ngIf="allHeaderCategories.searchType === '1'" class="custom-search-bar me-2">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search..." placeholder="Search..."
                        [formControl]="searchString" [matAutocomplete]="auto" autocomplete="off" #input
                        aria-label="input search for products" aria-label="input search for products">
                    <mat-autocomplete #auto="matAutocomplete" class="mat-elevation-z1">
                        <mat-option *ngFor="let item of productsList" [ngClass]="allHeaderCategories?.fontFamily"
                            class="fw-500" (click)="viewProductDetails(item?.baseProductId)"
                            [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                            <mat-icon
                                [ngStyle]="{'color': allHeaderCategories?.textColor+appData.borderOpacityFifty}">shopping_bag
                            </mat-icon><span>{{item.baseProductName}}</span>
                        </mat-option>
                        <mat-option *ngIf="productsList?.length == 0" [ngClass]="allHeaderCategories?.fontFamily"
                            class="fw-500"
                            [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                            No Products Found </mat-option>
                    </mat-autocomplete>
                    <div class="input-group-append">
                        <i class="bi bi-search" *ngIf="!inputVisible"
                            [ngStyle]="{'color':allHeaderCategories?.headerTextColor}"></i>
                    </div>
                </div>

            </div>

            <div *ngIf="allHeaderCategories.searchType === '2'" class="search-box me-2">
                <button class="btn-search">
                    <i class="bi bi-search" [ngStyle]="{'color':allHeaderCategories.headerTextColor}"></i>
                </button>
                <input type="text" class="input-search" placeholder="Type to Search..." placeholder="Search..."
                    [formControl]="searchString" [matAutocomplete]="auto" autocomplete="off" #input
                    aria-label="input search for products" aria-label="input search for products">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let item of productsList" [ngClass]="allHeaderCategories?.fontFamily"
                        class="fw-500" (click)="viewProductDetails(item?.baseProductId)"
                        [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                        <mat-icon
                            [ngStyle]="{'color': allHeaderCategories?.textColor+appData.borderOpacityFifty}">shopping_bag
                        </mat-icon><span>{{item.baseProductName}}</span>
                    </mat-option>
                    <mat-option *ngIf="productsList?.length == 0" [ngClass]="allHeaderCategories?.fontFamily"
                        class="fw-500"
                        [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                        No Products Found </mat-option>
                </mat-autocomplete>
            </div>

            <div *ngIf="allHeaderCategories.searchType === '3'" class="me-2">
                <div class="search-box3" [ngStyle]="{'border-color':allHeaderCategories.headerTextColor}">
                    <input type="text" class="search-input" placeholder="Search.." placeholder="Search..."
                        [formControl]="searchString" [matAutocomplete]="auto" autocomplete="off" #input
                        aria-label="input search for products" aria-label="input search for products">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let item of productsList" [ngClass]="allHeaderCategories?.fontFamily"
                            class="fw-500" (click)="viewProductDetails(item?.baseProductId)"
                            [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                            <mat-icon
                                [ngStyle]="{'color': allHeaderCategories?.textColor+appData.borderOpacityFifty}">shopping_bag
                            </mat-icon><span>{{item.baseProductName}}</span>
                        </mat-option>
                        <mat-option *ngIf="productsList?.length == 0" [ngClass]="allHeaderCategories?.fontFamily"
                            class="fw-500"
                            [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                            No Products Found </mat-option>
                    </mat-autocomplete>
                    <button class="search-button3">
                        <i class="bi bi-search" [ngStyle]="{'color':allHeaderCategories.headerTextColor}"></i>
                    </button>
                </div>
            </div>

            <div *ngIf="allHeaderCategories.searchType === '4'">
                <div class="flexbox8">
                    <div class="search8">
                        <div>
                            <input class="searchinput8" type="text" placeholder="Search . . ." required
                                placeholder="Search..." [formControl]="searchString" [matAutocomplete]="auto"
                                autocomplete="off" #input aria-label="input search for products"
                                aria-label="input search for products">
                            <mat-autocomplete class=" " #auto="matAutocomplete">
                                <mat-option *ngFor="let item of productsList"
                                    [ngClass]="allHeaderCategories?.fontFamily" class="fw-500"
                                    (click)="viewProductDetails(item?.baseProductId)"
                                    [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                                    <mat-icon
                                        [ngStyle]="{'color': allHeaderCategories?.textColor+appData.borderOpacityFifty}">shopping_bag
                                    </mat-icon><span>{{item.baseProductName}}</span>
                                </mat-option>
                                <mat-option *ngIf="productsList?.length == 0"
                                    [ngClass]="allHeaderCategories?.fontFamily" class="fw-500"
                                    [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                                    No Products Found </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="allHeaderCategories.searchType === '5'" class="me-2">
                <div id="container6">
                    <div class="search6">
                        <input type="text" placeholder="Search Here!" name="search" class="searchbar6"
                            [ngStyle]="{'color':allHeaderCategories.headerTextColor}" [formControl]="searchString"
                            [matAutocomplete]="auto" autocomplete="off" #input aria-label="input search for products"
                            aria-label="input search for products">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let item of productsList" [ngClass]="allHeaderCategories?.fontFamily"
                                class="fw-500" (click)="viewProductDetails(item?.baseProductId)"
                                [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                                <mat-icon
                                    [ngStyle]="{'color': allHeaderCategories?.textColor+appData.borderOpacityFifty}">shopping_bag
                                </mat-icon><span>{{item.baseProductName}}</span>
                            </mat-option>
                            <mat-option *ngIf="productsList?.length == 0" [ngClass]="allHeaderCategories?.fontFamily"
                                class="fw-500"
                                [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                                No Products Found </mat-option>
                        </mat-autocomplete>
                        <button type="submit" class="search_button6">
                            <i class="bi bi-search" style="color: black;"></i></button>
                    </div>
                </div>
            </div>

            <div *ngIf="allHeaderCategories.searchType === '6'">
                <div class="search-container9" [ngStyle]="{'color':allHeaderCategories.headerTextColor}">
                    <input class="search-input9" type="text" placeholder="Search"
                        [ngStyle]="{'color':allHeaderCategories.headerTextColor}" [formControl]="searchString"
                        [matAutocomplete]="auto" autocomplete="off" #input aria-label="input search for products"
                        aria-label="input search for products">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let item of productsList" [ngClass]="allHeaderCategories?.fontFamily"
                            class="fw-500" (click)="viewProductDetails(item?.baseProductId)"
                            [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                            <mat-icon
                                [ngStyle]="{'color': allHeaderCategories?.textColor+appData.borderOpacityFifty}">shopping_bag
                            </mat-icon><span>{{item.baseProductName}}</span>
                        </mat-option>
                        <mat-option *ngIf="productsList?.length == 0" [ngClass]="allHeaderCategories?.fontFamily"
                            class="fw-500"
                            [ngStyle]="{'background-color': allHeaderCategories?.pageBackgroundColor, 'color': allHeaderCategories?.textColor}">
                            No Products Found </mat-option>
                    </mat-autocomplete>
                </div>
            </div>

            <span class="me-3 link" *ngFor="let link of selectedLinksEnd"
                [ngStyle]="{'font-size':allHeaderCategories.fontHeight + 'px','font-weight':allHeaderCategories.fontWeight,'color':allHeaderCategories.headerTextColor}"
                [ngClass]="allHeaderCategories.fontStyle" style="text-decoration: none;">
                <span *ngIf="allHeaderCategories.navLinksEndIcon == false">{{link}}</span>
                <i class="bi bi-cart-fill " *ngIf="allHeaderCategories.navLinksEndIcon == true && link == 'Cart'"
                    (click)="navigateToCart()"></i>
                <i class="bi bi-heart-fill" *ngIf="allHeaderCategories.navLinksEndIcon && link == 'WishList'"
                    (click)="navigateToWishList()"></i>
                <div *ngIf="allHeaderCategories.navLinksEndIcon && link == 'Profile' &&  !isAuthenticated">
                    <i class="bi bi-person-fill" (mouseenter)="mouseEnter(profileTrigger)"
                        (mouseleave)="mouseLeave(profileTrigger)" #profileTrigger="matMenuTrigger"
                        [matMenuTriggerFor]="profileSubMenu" (error)="handleImageError($event)"></i>
                    <mat-menu class="p-3" #profileSubMenu="matMenu" [hasBackdrop]="false" xPosition="after"
                        [ngStyle]="{'background-color': allHeaderCategories.headerBackgroundColor , 'color': allHeaderCategories.headerTextColor}">
                        <span class="p-2" (mouseleave)="menuMouseLeave(profileTrigger)" (mouseenter)="trigger = 1"
                            style="background-color: black !important;" class=" mat-elevation-z1"
                            [ngClass]="allHeaderCategories.fontStyle">
                            <div class="row m-0">
                                <div *ngIf="!isAuthenticated">
                                    <span class="fs-md fw-500">Welcome</span>
                                </div>
                                <div *ngIf="!isAuthenticated">
                                    <span class="fs-x-sm fw-500">To access and manage orders</span>
                                </div>
                            </div>
                            <div class="mb-2" *ngIf="!isAuthenticated">
                                <button mat-stroked-button aria-label="login-route-button"
                                    [routerLink]="['/accounts/sign-in']" routerLinkActive="router-link-active"
                                    [ngStyle]="{'color':allPageColors.buttonTextColor,'background-color':allPageColors.buttonBackgroundColor }">LOGIN
                                    / SIGNUP</button>
                            </div>
                            <mat-divider style="border-top: 1px solid black;"></mat-divider>
                            <div>
                                <span class=" fs-sm submenu-links link" (click)="navigateToOrders()">Orders</span>
                            </div>
                            <div><span class="fs-sm submenu-links link" (click)="navigateToWishList()">Wishlist</span>
                            </div>
                            <div class="mb-1"><span class="fs-sm submenu-links link" (click)="navigateToCart()">Shopping
                                    Cart</span>
                            </div>

                            <mat-divider style="border-top: 1px solid black;"></mat-divider>
                            <div><span class="fs-sm submenu-links link" (click)="navigateToAddresses()">Saved
                                    Addresses</span></div>
                            <div> <span class="fs-sm submenu-links link " (click)="navigateToEditProfile()">Edit
                                    Profile</span></div>
                            <div><span class="fs-sm submenu-links link" (click)="logout()"
                                    *ngIf="isAuthenticated">Logout</span></div>
                        </span>
                    </mat-menu>
                </div>
                <div *ngIf="allHeaderCategories.navLinksEndIcon && link == 'Profile' &&  isAuthenticated">
                    <img style="width: 30px; height: 30px; border-radius: 50%; cursor: pointer;"
                        (mouseenter)="mouseEnter(profileTrigger)" (mouseleave)="mouseLeave(profileTrigger)"
                        #profileTrigger="matMenuTrigger" [matMenuTriggerFor]="profileSubMenu" class="border"
                        [src]=" userDetails ?ecomAppSettings.awsBasePath+userDetails.userImageUrl : '../../../../assets/images/default.jpg' " alt="profile-img"
                        (error)="handleImageError($event)" />
                    <mat-menu class="p-3" #profileSubMenu="matMenu" [hasBackdrop]="false" xPosition="after"
                        [ngStyle]="{'background-color': allHeaderCategories.headerBackgroundColor , 'color': allHeaderCategories.headerTextColor}">
                        <span class="p-2" (mouseleave)="menuMouseLeave(profileTrigger)" (mouseenter)="trigger = 1"
                            style="background-color: black !important;" class=" mat-elevation-z1"
                            [ngClass]="allHeaderCategories.fontStyle">
                            <div class="row m-0">
                                <div *ngIf="!isAuthenticated">
                                    <span class="fs-md fw-500">Welcome</span>
                                </div>
                                <div *ngIf="!isAuthenticated">
                                    <span class="fs-x-sm fw-500">To access and manage orders</span>
                                </div>
                                <div class="link d-flex justify-content-between align-items-center me-2"
                                    [routerLink]="['/user/profile']" routerLinkActive="router-link-active"
                                    *ngIf="isAuthenticated">
                                    <img *ngIf="userDetails?.userImageUrl"
                                    [src]=" userDetails ?ecomAppSettings.awsBasePath+userDetails.userImageUrl : '../../../../assets/images/default.jpg' " alt="profile-img"
                                        class="profile-img m-2" width="30%"
                                        [ngStyle]="{'border': allHeaderCategories.headerTextColor+appData.borderOpacityFifty}">
                                    <div *ngIf="!userDetails?.userImageUrl"
                                        class="profile-img d-flex justify-content-center align-items-center"
                                        [ngStyle]="{'border': appData.border+allHeaderCategories.headerTextColor+appData.borderOpacityFifty}">
                                        <span class="t-tr-u fw-500">{{userDetails?.userFullName |
                                            slice:0:1}}</span>
                                    </div>
                                    <div class="">
                                        <span class="fs-sm fw-500 t-tr-c text-ellipsis">
                                            {{userDetails?.userFullName}}
                                        </span><br>
                                        <span class="fs-x-sm fw-500 text-ellipsis">
                                            {{ userDetails?.userEmail}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2" *ngIf="!isAuthenticated">
                                <button mat-stroked-button aria-label="login-route-button"
                                    [routerLink]="['/accounts/sign-in']" routerLinkActive="router-link-active"
                                    [ngStyle]="{'color':allPageColors.buttonTextColor,'background-color':allPageColors.buttonBackgroundColor }">LOGIN
                                    / SIGNUP</button>
                            </div>
                            <mat-divider style="border-top: 1px solid black;"></mat-divider>
                            <div>
                                <span class=" fs-sm submenu-links link" (click)="navigateToOrders()">Orders</span>
                            </div>
                            <div><span class="fs-sm submenu-links link" (click)="navigateToWishList()">Wishlist</span>
                            </div>
                            <div class="mb-1"><span class="fs-sm submenu-links link" (click)="navigateToCart()">Shopping
                                    Cart</span>
                            </div>

                            <mat-divider style="border-top: 1px solid black;"></mat-divider>
                            <div><span class="fs-sm submenu-links link" (click)="navigateToAddresses()">Saved
                                    Addresses</span></div>
                            <div> <span class="fs-sm submenu-links link " (click)="navigateToEditProfile()">Edit
                                    Profile</span></div>
                            <div><span class="fs-sm submenu-links link" (click)="logout()"
                                    *ngIf="isAuthenticated">Logout</span></div>
                        </span>
                    </mat-menu>

                </div>
            </span>
        </div>

        <div *ngIf="allHeaderCategories.logoAlignment == 'Right'" class="d-flex justify-content-end align-items-center "
            style="width: 33.3%;">
            <img [src]=" headerBackgroundColor == 'transparent' ? allHeaderCategories.weblogo : allHeaderCategories.moblogo "
                class="logo link" alt="app logo"
                [ngStyle]="{'width': allHeaderCategories.logoWidth != '0' ? allHeaderCategories.logoWidth +'px' : '100px','height':allHeaderCategories.logoHeight != '0' ? allHeaderCategories.logoHeight : ''}"
                [routerLink]="['/']" (error)="handleImageError($event)">
        </div>

    </div>

    <div class="mobile d-sm-flex d-lg-none d-flex justify-content-between  align-items-center w-100 pe-3 ps-2"
         [ngStyle]="{'height':allHeaderCategories.headerHight + 'px'}">
        <div class=" center d-flex align-items-center">
            <span class="me-3">
                <mat-icon (click)="toggle.emit()" style="font-size: 2rem !important;"  [ngStyle]="{'color': allHeaderCategories.headerTextColor}" >segment</mat-icon>
            </span>
            <span>
                <a [routerLink]="['/']" aria-label="navigate to home" fxLayoutAlign="start center">
                    <img [src]=" headerBackgroundColor == 'transparent' ? allHeaderCategories.weblogo : allHeaderCategories.moblogo "
                        class="logo" alt="app logo"
                        [ngStyle]="{'width': allHeaderCategories.logoWidth != '0' ? allHeaderCategories.logoWidth +'px' : '100px','height':allHeaderCategories.logoHeight != '0' ? allHeaderCategories.logoHeight : ''}"
                        (error)="handleImageError($event)">
                </a>
            </span>
        </div>
        <div class=" d-flex justify-content-end align-items-center">
            <a class="ms-3 text-decoration-none" [ngStyle]="{'color': allHeaderCategories.headerTextColor}" [routerLink]="['/products']" routerLinkActive="router-link-active" (click)="otherProducts('categories')">
                <i class="bi bi-search fs-5 cursor"></i>
            </a>

            <a class="ms-3 text-decoration-none" [ngStyle]="{'color': allHeaderCategories.headerTextColor}" (click)="navigateToCart()">
                <i class="bi bi-bag fs-5 cursor" [matBadge]="cartItemsLength" matBadgeColor="warn" matBadgeSize="small"
                    (click)="navigateToCart()"></i>
            </a>

            <a class="ms-3 text-decoration-none" (click)="navigateToProfileOverview()" *ngIf="isAuthenticated"
                aria-label="profilr route button">
                <img style="width: 35px; height: 35px; border-radius: 50%; cursor: pointer;" class="border"
                [src]=" userDetails.userImageUrl ? ecomAppSettings.awsBasePath+userDetails.userImageUrl : '../../../../assets/images/default.jpg' " />
            </a>

            <a class="text-decoration-none" [ngStyle]="{'color': allHeaderCategories.headerTextColor}" [routerLink]="['/accounts/sign-in']" *ngIf="!isAuthenticated" aria-label="login route button"
                class="p-0">
                <span class="fw-500 ms-3">Login</span>
            </a>
        </div>
    </div>

    <app-header-categories class="w-100" *ngIf="allHeaderCategories.dropDownStyle != '4'"></app-header-categories>

    <div *ngIf=" allHeaderCategories.dropDownStyle == '4'"
        [ngClass]=" allHeaderCategories.dropDownStyle == '4' ? openCategories : ''"
        [ngStyle]="{'background-color':headerBackgroundColor}"
        style="width: 100%;position: relative;bottom: 0px;z-index: -20;" (mouseleave)="hideCategories()">
        <div [ngStyle]="{'background-color':headerBackgroundColor}"
            [ngStyle]="{'width': '100%','position': 'absolute','bottom' : '0px'}">
            <app-header-categories></app-header-categories>
        </div>
    </div>

</mat-toolbar>
