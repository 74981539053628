/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetMyEcomVoucherList } from './getMyEcomVoucherList';
import { EcomAddressDetails } from './ecomAddressDetails';
import { CourierShipmetDetails } from './courierShipmetDetails';
import { EcomOrdersItems } from './ecomOrdersItems';


export interface EcomOrdersList { 
    order_ID?: number;
    fK_AppUser_Id?: number | null;
    order_Status?: string | null;
    order_SubTotal?: number | null;
    product_Discount?: number | null;
    order_Tax?: number | null;
    order_Shipping?: number | null;
    order_Total?: number | null;
    order_Promo?: string | null;
    order_Discount?: number | null;
    order_GrandTotal?: number | null;
    order_Date?: string | null;
    isCod?: boolean;
    payment_Status?: string | null;
    payment_Details?: string | null;
    fK_Razor_Order_Ids?: string | null;
    isReturned?: boolean;
    isReturnPolicyExpired?: boolean;
    shippingAddress?: EcomAddressDetails;
    ecomItemDetails?: Array<EcomOrdersItems> | null;
    courierShipmetDetails?: CourierShipmetDetails;
    rewardedVouchers?: GetMyEcomVoucherList;
}

