/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetCouponsByCart { 
    id?: number;
    code?: string | null;
    name?: string | null;
    description?: string | null;
    guideLines?: string | null;
    discountPercentage?: number;
    minimumValue?: number | null;
    maximumDiscount?: number | null;
    isFlat?: boolean;
    isCashback?: boolean;
    expiresOn?: string | null;
    image?: string | null;
}

