/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttributeModel } from './attributeModel';


export interface BaseProductDetailObj { 
    baseProductDescription?: string | null;
    tags?: string | null;
    baseProductWeight?: string | null;
    hsnCode?: string | null;
    attributes?: Array<AttributeModel> | null;
    outletName?: string | null;
    outletAddress?: string | null;
    outletEmail?: string | null;
    whatsappNumber?: string | null;
    outletPhoneNo?: string | null;
    outletLogo?: string | null;
    isDeleted?: boolean | null;
}

