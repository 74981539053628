import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeleteProfileComponent } from './_modules/additional-pages/delete-profile/delete-profile.component';

const routes: Routes = [ { path: '', loadChildren: () => import('./_modules/home-pages/home-pages.module').then(m => m.HomePagesModule) },
{ path: 'home', loadChildren: () => import('./_modules/home-pages/home-pages.module').then(m => m.HomePagesModule) },
{ path: 'accounts', loadChildren: () => import('./_modules/account/account.module').then(m => m.AccountModule) },
{ path: 'products', loadChildren: () => import('./_modules/products/products.module').then(m => m.ProductsModule) },
{ path: 'user', loadChildren: () => import('./_modules/user/user.module').then(m => m.UserModule), },
{ path: 'wishlist', loadChildren: () => import('./_modules/wishlist/wishlist.module').then(m => m.WishlistModule)},
{ path: 'cart', loadChildren: () => import('./_modules/shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule) },
{ path: 'about-us', loadChildren: () => import('./_modules/additional-pages/about-us/about-us.module').then(m => m.AboutUsModule)},
{ path: 'shipping', loadChildren: () => import('./_modules/additional-pages/about-us/about-us.module').then(m => m.AboutUsModule)},
{ path: 'return-policy', loadChildren: () => import('./_modules/additional-pages/about-us/about-us.module').then(m => m.AboutUsModule)},
{ path: 'terms-of-use', loadChildren: () => import('./_modules/additional-pages/about-us/about-us.module').then(m => m.AboutUsModule)},
{ path: 'privacy', loadChildren: () => import('./_modules/additional-pages/about-us/about-us.module').then(m => m.AboutUsModule)},
{ path: 'contact-us', loadChildren: () => import('./_modules/additional-pages/contact-us/contact-us.module').then(m => m.ContactUsModule) },
{ path: 'delete-profile',loadChildren:() => import('./_modules/additional-pages/delete-profile/delete-profile.module').then(m => m.DeleteProfileModule)},
]
;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
