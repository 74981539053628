/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Ecommodel } from './ecommodel';


export interface EcomTopicWebModel { 
    isVisible?: boolean;
    topicColor?: string | null;
    topicTextColor?: string | null;
    fK_Brandid?: number | null;
    topic_Id?: number | null;
    topic_Name?: string | null;
    topic_Description?: string | null;
    topic_CategoryYN?: boolean;
    addyn?: boolean | null;
    isNewArrival?: boolean | null;
    slider?: boolean | null;
    filename?: Blob | null;
    extension?: string | null;
    priority?: number | null;
    isSticky?: boolean;
    isSquare?: boolean;
    alignment?: string | null;
    isFrameRequired?: boolean;
    topicFontType?: string | null;
    topicFontSize?: string | null;
    showDiscount?: boolean;
    withFee?: boolean;
    buyNow?: boolean;
    addToCart?: boolean;
    aspectRatio?: string | null;
    advertisementType?: string | null;
    mobileAspectRatio?: string | null;
    categoryMasterId?: number | null;
    ecomDetails?: Array<Ecommodel> | null;
}

