// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {

  production: false,
  // basePath: "https://admin.yoneak.in",
  // basePath: "https://portal.yoneak.com",
  basePath: "https://ecom.yoneak.in",
  blobStoragePath: 'https://yoneak.s3.ap-south-1.amazonaws.com/',
  border: '1px solid ',
  borderOpacity: '33',
  borderOpacityFifty: '80',
  isPoweredBy: true,

  // Doulee
  // domainUrl: "http://doulee.in",
  // brandId: 197,
  // appVersion: '1.0.0',
  // title: "DouLee | Customized branded apparel seller",
  // keywords: "t shirt for mens india, t shirt for men online, polo t shirts, sport t shirt, black t shirt, blue t shirt, white t shirt",
  // description: "Men's T-shirts - Buy T-shirts for men online in India. Choose from a wide range of polo, round, V neck & more Men's T-shirts in various designs at Doulee.",

  // Krishna Born Baby
  // domainUrl:"https://www.krishnabornbaby.com",
  // brandId: 202,
  // appVersion: '1.0.0',
  // title: "Krishna Born Baby",
  // keywords: "Krishna Born Baby",
  // description: "Krishna Born Baby",

  //new
  // domainUrl:"https://www.krishnabornbaby.com",
  // brandId: 355,
  // appVersion: '1.0.0',
  // title: "Krishna Born Baby",
  // keywords: "Krishna Born Baby",
  // description: "Krishna Born Baby",


  // Orange Blog Surat
  // domainUrl: "http://orangeblogsurat.com",
  // brandId: 200,
  // appVersion: '1.0.0',
  // title: "Orange Blog Surat",
  // keywords: "Orange Blog Surat",
  // description: "Orange Blog Surat",

  // Meomsha
  // domainUrl:"http://meomsha.com",
  // brandId: 221,
  // appVersion: '1.0.0',
  // title: "Meomsha | Customized clothes for new generation",
  // keywords: "Meomsha | Customized clothes for new generation",
  // description: "Meomsha | Customized clothes for new generation",



  //Light Studio
  // domainUrl: "http://yoneak.in",
  // brandId: 253,
  // appVersion: '1.0.0',
  // title: "Light Studio ",
  // keywords: "Light Studio ",
  // description: "Light Studio ",


  // Piksiya
  // domainUrl: "http://demo.yoneak.in",
  // brandId: 255,
  // appVersion: '1.0.0',
  // title: "Piksiya ",
  // keywords: "Piksiya ",
  // description: "Piksiya ",

  domainUrl: "http://demo2.yoneak.in",
  brandId: 397,
  appVersion: '1.0.0',
  title: "786Rider ",
  keywords: "786Rider ",
  description: "786Rider ",
  // domainUrl: "http://demo2.yoneak.in",
  // brandId: 552,
  // appVersion: '1.0.0',
  // title: "786Rider ",
  // keywords: "786Rider ",
  // description: "786Rider ",

    // deekshit
    // domainUrl: "http://demo.yoneak.in",
    // brandId: 396,
    // appVersion: '1.0.0',
    // title: "Piksiya ",
    // keywords: "Piksiya ",
    // description: "Piksiya ",

  //metaTronics
  // domainUrl:"http://demo.yoneak.in",
  // brandId: 463,
  // appVersion: '1.0.0',
  // title: "Metatronics ",
  // keywords: "Metatronics ",
  // description: "Metatronics ",

  //motor
  // domainUrl:"http://demo.yoneak.in",
  // brandId: 367,
  // appVersion: '1.0.0',
  // title: "Super bikes ",
  // keywords: "Super bikes ",
  // description: "Super bikes ",


  //nishpdita
  // domainUrl:"http://demo.yoneak.in",
  // brandId: 371,
  // appVersion: '1.1.0',
  // title: "Nishpaditha",
  // keywords: "Nishpaditha",
  // description: "Nishpaditha",

  //vastra
  // domainUrl:"http://demo.yoneak.in",
  // brandId: 355,
  // appVersion: '1.0.0',
  // title: "vastraimitation",
  // keywords: "vastraimitation",
  // description: "vastraimitation",

  //test
  // domainUrl:"http://demo.yoneak.in",
  // brandId: 341,
  // appVersion: '1.0.0',
  // title: "Piksiya ",
  // keywords: "Piksiya ",
  // description: "Piksiya ",

  //treasurebymeena
  // domainUrl:"http://treasuresbymeena.com",
  // brandId: 375,
  // appVersion: '1.0.0',
  // title: "Treasurebymeena ",
  // keywords: "Treasurebymeena ",
  // description: "Treasurebymeena ",

  // Khazaana
  // domainUrl:"http://demo1.yoneak.in",
  // brandId: 251,
  // appVersion: '1.0.4',
  // title: "Khazaana | Customized jewelry for new generation",
  // keywords: "Khazaana | Customized jewelry for new generation",
  // description: "Khazaana | Customized jewelry for new generation",


  // Croma
  // domainUrl:"http://demo2.yoneak.in",
  // brandId: 259,
  // appVersion: '1.0.0',
  // title: "Croma ",
  // keywords: "Croma ",
  // description: "Croma ",

  // Footbox
  // domainUrl: "http://demo3.yoneak.in",
  // brandId: 259,
  // appVersion: '1.0.0',
  // title: "Footbox ",
  // keywords: "Footbox ",
  // description: "Footbox ",


  // Aroma
  // domainUrl: "http://demo3.yoneak.in",
  // brandId: 274,
  // appVersion: '1.0.0',
  // title: "Aroma ",
  // keywords: "Aroma ",
  // description: "Aroma ",


  // Speedbreaker
  // domainUrl: "http://speedbreaker.cafe",
  // brandId: 280,
  // appVersion: '1.0.0',
  // title: "Speedbreaker ",
  // keywords: "Speedbreaker ",
  // description: "Speedbreaker ",


  // MG Marketing
  // domainUrl: "https://mgmarketing.in",
  // brandId: 282,
  // appVersion: '18.18.18',
  // title: "MG Marketing",
  // keywords: "MG Marketing",
  // description: "MG Marketing",

  // SAIRA
  // domainUrl: "http://sai-ra.com",
  // brandId: 281,
  // appVersion: '1.0.0',
  // title: "SAIRA",
  // keywords: "SAIRA",
  // description: "SAIRA",

  // Neon Ecom
  // domainUrl: "http://demo3.yoneak.in",
  // brandId: 274,
  // appVersion: '1.0.0',
  // title: "Neon Ecom",
  // keywords: "Neon Ecom",
  // description: "Neon Ecom",

  // Bhavani Traders
  //  domainUrl: "http://bhavani-traders.com",
  //  brandId: 289,
  //  appVersion: '1.0.1',
  //  title: "Bhavani Traders",
  //  keywords: "Bhavani Traders",
  //  description: "Bhavani Traders",


  // Rangreet
  //  domainUrl: "http://demo3.yoneak.in",
  //  brandId: 298,
  //  appVersion: '1.1.1',
  //  title: "Rangreet Sarees",
  //  keywords: "Rangreet Sarees",
  //  description: "Rangreet Sarees",

  // Padam Distributors
  // domainUrl: "http://padamdistributors.in",
  // brandId: 282,
  // appVersion: '1.0.0',
  // title: "Padam Distributors",
  // keywords: "Padam Distributors",
  // description: "Padam Distributors",


  // Yuganthara
  // domainUrl: "https://yuganthara.com",
  // brandId: 320,
  // appVersion: '1.0.2',
  // title: "Yuganthara",
  // keywords: "Yuganthara",
  // description: "Yuganthara",


  // Sorav Textiles
  // domainUrl: "http://soravfashion.com",
  // brandId: 332,
  // appVersion: '1.0.2',
  // title: "Sorav Textiles",
  // keywords: "Sorav Textiles",
  // description: "Sorav Textiles",


  // Bridal Collection
  // domainUrl: "http://demo2.yoneak.in",
  // brandId: 364,
  // appVersion: '1.1.2',
  // title: "Bridal Collection",
  // keywords: "Bridal Collection",
  // description: "Bridal Collection",

  // ANGI & CO
  // domainUrl: "http://demo3.yoneak.in",
  // brandId: 341,
  // appVersion: '1.0.1',
  // title: "ANGI & CO Collection",
  // keywords: "ANGI & CO Collection",
  // description: "ANGI & CO Collection",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
