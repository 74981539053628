import { Component, ElementRef, HostListener, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Categorymodel, DynamicHeaderModelGet, EcomBrandwiseApkThemeData, EcomWebAuthService, SelectProductMob } from 'build/openapi';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AccountService } from 'src/app/_services/account.service';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-header-categories',
  templateUrl: './header-categories.component.html',
  styleUrls: ['./header-categories.component.scss']
})
export class HeaderCategoriesComponent implements OnInit {

  @Input('isHomePage')
  isHomePage!: boolean;
  productCategories: SelectProductMob[] = [];
  ecomAppSettings!: EcomBrandwiseApkThemeData;
  borderUi!: string;
  categories: Categorymodel[] = [];

  get blobStoragePath(): any {
    return this.ecomAppSettings.awsBasePath;
  }
  get brandId(): number {
    return environment.brandId;
  }
  get border(): string {
    return environment.border;
  }
  get borderOpacity(): string {
    return environment.borderOpacity;
  }
  customOptions!: OwlOptions;
  // isHomePage: boolean;
  scrollPosition: number = 0;
  private platformId: Object;
  constructor(
    private ecomWebAuthService: EcomWebAuthService,
    private router: Router,
    private accountService: AccountService, @Inject(PLATFORM_ID) platformId: Object) {
    this.platformId = platformId;

    if (isPlatformBrowser(this.platformId)) {

      this.isHomePage = window.location.pathname === '/';
    }

  }

  headerStyle: DynamicHeaderModelGet = {};
  headerBackgroundColor: any
  color: any;
  @ViewChild('hederheight') header!: ElementRef;
  headerHeight: number = 0;
  ngOnInit(): void {
    this.accountService.ecomAppSettingDetailsListner$.subscribe(res => {
      if (res) {
        this.ecomAppSettings = res;
        this.borderUi = this.border + this.ecomAppSettings?.textColor + this.borderOpacity;
        this.getHeaderCategories();
      }
    });

    this.accountService._headerStyles$.subscribe(res => {
      if (res) {
        this.headerStyle = res;
        // this.color = res;
      }
    });

    this.accountService.headerBackgroundColor$.subscribe(res => {
      if (res) {
        this.headerBackgroundColor = res;
        // this.getHeaderCategories();
      }
    });
  }
  // headerStyle: any;
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollPosition = window.pageYOffset;
    }

  }

  shouldShowImages(item: any): any {
    if (isPlatformBrowser(this.platformId)) {
      const isHomePage = window.location.pathname === '/';
      const isCategoryIconHidden = item.ecomAppSettings?.isCategoryIconHidden;
      const showAllCategory = this.headerStyle?.showAllCategory;

      return !((this.scrollPosition > 58 || !isHomePage) && !isCategoryIconHidden && showAllCategory);
    }


  }

  shouldShowImagess(): any {
    if (isPlatformBrowser(this.platformId)) {
      const isHomePage = window.location.pathname === '/';
      const isCategoryIconHidden = this.ecomAppSettings?.isCategoryIconHidden;
      const showAllCategory = this.headerStyle?.showAllCategory;

      return !(this.scrollPosition > 0 || !isHomePage || isCategoryIconHidden || !showAllCategory);
    }

  }

  shouldShowMenu(): boolean {
    const isSignInPage = this.router.url === '/accounts/sign-in';
    const isSignUpPage = this.router.url === '/accounts/sign-up';
    return !(isSignInPage || isSignUpPage);
  }

  getHeaderCategories() {
    this.ecomWebAuthService.apiEcomWebAuthEcomCatSubPresenceByBrandIdGet(this.brandId).subscribe(res => {
      if (res.statusCode == 1) {
        this.categories = res.data as Categorymodel[]
        this.productSubCategories = this.categories[0].subCategoryModel;
        this.categoryObject = {
          id: this.categories[0].id,
          masterId: this.categories[0].masterId
        }
        this.customOptions = {
          loop: false,
          mouseDrag: true,
          touchDrag: true,
          pullDrag: true,
          dots: true,
          lazyLoad: true,
          navSpeed: 300,
          slideBy: 3,
          navText: ['<', '>'],
          responsive: {
            0: {
              items: 2
            },
            320: {
              items: 3
            },
            480: {
              items: 5
            },
            599: {
              items: 5
            },
            959: {
              items: 6
            },
            1279: {
              items: 6
            }
          },
          nav: true
        }

        setTimeout(() => {

          this.accountService._subCategoriesHeight.next(this.header.nativeElement.offsetHeight)

        }, 1000)

      }
      //   if (res.data) {
      //     this.categories = res.data;
      //     if (this.categories.length == 1) {
      //       this.ecomWebAuthService.apiEcomWebAuthEcomProductSubCategoryMasterBrandwiseDDLGet(res.data[0].value, this.brandId).subscribe(res => {
      //         this.productCategories = res.data as SelectProductMob[];

      //         const newCategory: SelectProductMob = {
      //           name: "OTHERS",
      //           value: 0,
      //           path: '../../../../../assets/images/others.jpg',
      //           isVisible: null,
      //           subCategoryCount: 0,
      //           categoryId: 112,
      //           categoryMasterId: 1,
      //           categoryName: 'aaaa',
      //           brandList: null,
      //           productCount: 0,
      //           subCategory: null,
      //         };
      //         this.productCategories.push(newCategory);
      //       })
      //     }
      //     else {

      //       this.productCategories = res.data as SelectProductMob[];

      //       console.log(this.productCategories.subCategory)
      //       // const newCategory: SelectProductMob = {
      //       //   name: "OTHERS",
      //       //   value: 0,
      //       //   path: '../../../../../assets/images/others.jpg',
      //       //   isVisible: null,
      //       //   subCategoryCount: 0,
      //       //   categoryId: 112,
      //       //   categoryMasterId: 1,
      //       //   categoryName: 'aaaa',
      //       //   brandList: null,
      //       //   productCount: 0,
      //       //   subCategory: null,
      //       // };
      //       // this.productCategories.push(newCategory);
      //     }
      //     this.customOptions = {
      //       loop: false,
      //       mouseDrag: true,
      //       touchDrag: true,
      //       pullDrag: true,
      //       dots: true,
      //       lazyLoad: true,
      //       navSpeed: 300,
      //       slideBy: 3,
      //       navText: ['<', '>'],
      //       responsive: {
      //         0: {
      //           items: 3
      //         },
      //         320: {
      //           items: 4
      //         },
      //         480: {
      //           items: 5
      //         },
      //         599: {
      //           items: 5
      //         },
      //         959: {
      //           items: 6
      //         },
      //         1279: {
      //           items: 6
      //         }
      //       },
      //       nav: true
      //     }
      //   }
      // }, (error: HttpErrorResponse) => {
      //   if (error.status == 401) {
      //     this.accountService.logout();
      //   }
      // });
    })
  }

  viewAll() {
    // debugger
    if (this.categories.length == 1) {
      this.router.navigate(['/products/sub-categories'], {
        // queryParams: {
        //   categoryId: btoa(this.categories[0]?.value),
        //   categoryName: btoa(this.categories[0]?.name),
        // }
      });
    }
    else {
      this.router.navigate(['/products/categories']);
    }
  }

  viewProductSubCategories(category: any): void {
    if (this.categories.length == 1 || category === 'none-categories') {
      this.router.navigate(['/products'], {
        queryParams: {
          // categoryMasterId: btoa(this.categories[0].categoryMasterId.toString()),
          // categoryId: btoa(this.categories[0].value.toString()),
          // categoryName: btoa(this.categories[0].name.toString()),
          // subCategoryId: btoa(category?.value),
          // subCategoryName: btoa(category?.name),
          // noneCategory: category === 'none-categories' ? category : 'categories'
        }
      });
    }
    else if (category === 'none-categories') {
      this.router.navigate(['/products'], {
        queryParams: {
          // categoryMasterId: btoa(this.categories[0].categoryMasterId),
          // categoryId: btoa(this.categories[0].value),
          // categoryName: btoa(this.categories[0].name),
          // subCategoryId: btoa(category?.subcategoryMasterId),
          // subCategoryName: btoa(category?.name),
          // noneCategory: category === 'none-categories' ? category : 'categories'
        }
      });
    }
    else {

      this.router.navigate(['/products/sub-categories'], {
        queryParams: {
          categoryId: btoa(category?.id),
          categoryName: btoa(category?.name),
        }
      });
    }
  }

  getCategories(data: any[]) {
    return data.slice(0, 4)
  }
  productSubCategories: any

  categoryObject: any
  getCtegoryId(category: any) {
    this.categoryObject = {
      id: category.id,
      masterId: category.masterId
    }
  }

  getAllSubCategories(subCategory: any) {

    this.productSubCategories = subCategory


  }

  viewProductsDropDownFour(subCategory: any) {
    this.router.navigate(['/products'], {
      queryParams: {
        categoryId: btoa(this.categoryObject.id),
        // categoryName: btoa(category.categoryName),
        subCategoryId: btoa(subCategory.id),
        categoryMasterId: btoa(this.categoryObject.masterId),
        // subCategoryId: btoa(subCategory?.subcategoryMasterId),
        subCategoryName: btoa(subCategory.subCategoryName),
        noneCategory: 'categories'
      }
    });
  }


  viewProducts(subCategory: any, category: any, noneCategory: any): void {

    this.router.navigate(['/products'], {
      queryParams: {
        categoryId: btoa(category.id),
        categoryName: btoa(category.categoryName),
        subCategoryId: btoa(subCategory.id),
        categoryMasterId: btoa(category.masterId),
        // subCategoryId: btoa(subCategory?.subcategoryMasterId),
        subCategoryName: btoa(subCategory.subCategoryName),
        noneCategory: noneCategory === 'none-categories' ? noneCategory : 'categories'
      }
    });
  }

  style: boolean = false
}
