/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MasterTheams { 
    id?: number;
    themeName?: string | null;
    headerBackgroundColor?: string | null;
    headerTextColor?: string | null;
    buttonBackgroundColor?: string | null;
    buttonTextColor?: string | null;
    pageBackgroundColor?: string | null;
    textColor?: string | null;
    cardBackgroundColor?: string | null;
    cardTextColor?: string | null;
    topicBackgroundColor?: string | null;
    topicTextColor?: string | null;
    fontFamily?: string | null;
}

