/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DynamicFooterModel { 
    id?: number;
    footerBackgroundColor?: string | null;
    footerTextColor?: string | null;
    links?: boolean | null;
    topPadding?: string | null;
    bottomPadding?: string | null;
    fontSize?: string | null;
    fontWeight?: string | null;
    policies?: string | null;
    content?: string | null;
    brandId?: number;
}

