/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EcomProductBrandForBaseProduct { 
    productBrandId?: number | null;
    productBrandName?: string | null;
    productCategoryId?: number | null;
    productSubCategoryId?: number | null;
    isApprovedByAdmin?: boolean | null;
    isApprovedByUser?: boolean | null;
    createdDate?: string | null;
    userId?: number | null;
    userName?: string | null;
    productCount?: number;
}

