/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubCategoryModel } from './subCategoryModel';


export interface Categorymodel { 
    id?: number;
    masterId?: number | null;
    path?: string | null;
    categoryName?: string | null;
    subCategoryModel?: Array<SubCategoryModel> | null;
}

