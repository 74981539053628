export const constant = {
    roles: {
        ROLE_ECOM_USER: 'EcomUser',
    },
    authData: {
        access_token: 'access_token',
        expires_in: 'expires_in'
    },
    mainAccount: {
        photo_path: 'image',
        account_name: 'name',
        account_gender: 'account_gender',
    }
  }