import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DynamicHeaderModelGet, EcomBrandwiseApkThemeData, EcomWebAuthService, SelectProductMob, WebSettingModel } from 'build/openapi';
import { ModelNameByBrandIds } from 'build/openapi/model/modelNameByBrandIds';
import { AccountService, UserDetails } from 'src/app/_services/account.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent implements OnInit {   
  @Input('allPageColors') allPageColors: any | undefined;
  @Input('headerStyles') headerStyles: DynamicHeaderModelGet | undefined;
  @Output() toggle = new EventEmitter();
  categoriesDDL: any;
  nonCategoryName: ModelNameByBrandIds = {};
  get appData() {
    return environment;
  }
  isAuthenticated: boolean | undefined;
  userDetails: UserDetails | undefined;

  constructor(private router: Router, private snackBar: MatSnackBar, private accountService: AccountService,private ecomWebAuthService:EcomWebAuthService) { }

  ngOnInit(): void {
    this.accountService.loginListner$.subscribe(data => {
      this.isAuthenticated = data;
    })
    this.accountService.userDetailsListner$.subscribe(data => {
      this.userDetails = data;
    });


    this.getCategoriesAndSubCategories() 
    this.ecomWebAuthService.apiEcomWebAuthGetNonCategorizedNameGet(this.appData.brandId).subscribe((res) => {
      if (res.data != null) {
        this.nonCategoryName = res.data as ModelNameByBrandIds
      }
      else {
        let data: ModelNameByBrandIds = {
          id: 0,
          brandId: 0,
          modelName: 'OTHERS',
          isDelete: false
        }
        this.nonCategoryName = data

      }
    })
  }
  navigateToAllCategories(): void{
    this.toggle.emit();
    this.router.navigate(['/products/categories']);
  }
  navigateToCart(): void {
    this.toggle.emit();
    if (this.isAuthenticated) {
      this.router.navigate(['/cart']);
    }
    else {
      this.router.navigate(['/cart']);
      this.snackBar.open('Please login to view shopping cart', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  navigateToWishList(): void {
    this.toggle.emit();
    if (this.isAuthenticated) {
      this.router.navigate(['/wishlist']);
    }
    else {
      this.router.navigate(['/wishlist']);
      this.snackBar.open('Please login to view wishlist', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  navigateToOrders(): void {
    this.toggle.emit();
    if (this.isAuthenticated) {
      this.router.navigate(['/user/order/list']);
    }
    else {
      this.router.navigate(['/user/order/list']);
      this.snackBar.open('Please login to view orders list', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  navigateToAddresses(): void {
    this.toggle.emit();
    if (this.isAuthenticated) {
      this.router.navigate(['/user/manage-address']);
    }
    else {
      this.router.navigate(['/user/manage-address']);
      this.snackBar.open('Please login to view manage addresses', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  navigateToProfile(): void {
    this.toggle.emit();
    if (this.isAuthenticated) {
      this.router.navigate(['/user/profile']);
    }
    else {
      this.router.navigate(['/user/profile']);
      this.snackBar.open('Please login to view profile', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  navigateToEditProfile(): void {
    this.toggle.emit();
    if (this.isAuthenticated) {
      this.router.navigate(['/user/edit-profile']);
    }
    else {
      this.router.navigate(['/user/edit-profile']);
      this.snackBar.open('Please login to view edit profile', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  navigateToUpdatePassword(): void {
    this.toggle.emit();
    if (this.isAuthenticated) {
      this.router.navigate(['/user/update-password']);
    }
    else {
      this.router.navigate(['/user/update-password']);
      this.snackBar.open('Please login to view update password', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  navigateToProfileOverview(): void {
    this.toggle.emit();
    if (this.isAuthenticated) {
      this.router.navigate(['/user/profile-overview']);
    }
    else {
      this.router.navigate(['/user/profile-overview']);
      this.snackBar.open('Please login to view profile overview', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  navigateToWallet(): void {
    this.toggle.emit();
    if (this.isAuthenticated) {
      this.router.navigate(['/user/wallet']);
    }
    else {
      this.router.navigate(['/user/wallet']);
      this.snackBar.open('Please login to view wallet', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  navigateToVouchers(): void {
    this.toggle.emit();
    if (this.isAuthenticated) {
      this.router.navigate(['/user/vouchers']);
    }
    else {
      this.router.navigate(['/user/vouchers']);
      this.snackBar.open('Please login to view vouchers', 'OK', { panelClass: 'warning', duration: 3000 });
    }
  }
  logout() {
    this.toggle.emit();
    this.accountService.logout();
  }

  getCategoriesAndSubCategories() {
    this.ecomWebAuthService.apiEcomWebAuthCategoryDDLWithProductPresenceGet(this.appData.brandId).subscribe(res => {
      this.categoriesDDL = res.data as SelectProductMob;
    })
  }

  otherProducts(category: any) {

    this.router.navigate(['/products'], {
      queryParams: {
        categoryMasterId: btoa(this.categoriesDDL[0].categoryMasterId),
        categoryId: btoa(this.categoriesDDL[0].value),
        categoryName: btoa(this.categoriesDDL[0].name),
        subCategoryId: btoa(this.categoriesDDL[0]?.subCategory.value),
        subCategoryName: btoa(this.categoriesDDL[0]?.subCategory.name),
        noneCategory: category === 'none-categories' ? category : 'categories'
      }
    });
  }

}
