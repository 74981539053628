/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BaseProductDetailObj } from './baseProductDetailObj';
import { BaseProductColorModel } from './baseProductColorModel';
import { ReviewsModel } from './reviewsModel';
import { RatingsModel } from './ratingsModel';
import { ProductImagePaths } from './productImagePaths';


export interface GetEcomProductModel { 
    metaDescription?: string | null;
    snippetPreview?: string | null;
    focusedKeyword?: string | null;
    productMetaTitle?: string | null;
    categoryId?: number;
    categoryName?: string | null;
    subCategoryId?: number;
    subCategoryName?: string | null;
    isFavorite?: boolean | null;
    hsnCode?: string | null;
    productWeight?: string | null;
    baseProductId?: number | null;
    baseProductName?: string | null;
    baseProductDescription?: string | null;
    brandId?: number | null;
    outletId?: number | null;
    shippingCharges?: number | null;
    isFreeDelivery?: number | null;
    isVisible?: boolean;
    fkGst?: number | null;
    countryOfOrigin?: string | null;
    manufacturer?: string | null;
    quantity?: number;
    unitTypeId?: number | null;
    link?: string | null;
    isPercentage?: boolean;
    discount?: number | null;
    gst?: number | null;
    productImagePaths?: Array<ProductImagePaths> | null;
    baseProductColorModel?: Array<BaseProductColorModel> | null;
    baseProductObj?: BaseProductDetailObj;
    reviewsModel?: Array<ReviewsModel> | null;
    ratingsModel?: RatingsModel;
    averageRatings?: number | null;
    noOfReviews?: number | null;
}

