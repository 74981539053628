<!-- <div class="main-footer w-100 d-flex justify-content-center"
    [ngStyle]="{'padding-top':getAllFooterData.topPadding + 'px', 'padding-bottom':getAllFooterData.bottamPadding + 'px' , 'background-color': getAllFooterData.footerbackgroundcolor, 'color':getAllFooterData.footertextcolor}">
    <div class="footer d-flex flex-column w-90">
        <div class="row m-0 justify-content-between">

            <div class="right col-lg-3 d-flex flex-column gap-4" *ngIf="footerAlignment == 'left'">
                <div *ngIf="social == true">
                    <img src="../../../../assets/images/default2.jpg" alt="App Logo">
                </div>
            
                <div class="social d-flex gap-2" *ngIf="social == true">
                    <a [style]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-facebook"></i></a>
                    <a [style]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-whatsapp"></i></a>
                    <a [style]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-pinterest"></i></a>
                    <a [style]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-instagram"></i></a>
                    <a [style]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-youtube"></i></a>
                    <a [style]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-linkedin"></i></a>
                </div>
            </div>

            <div class="left col-lg-8 d-flex justify-content-between">
                <div class="links col-lg-1" *ngIf="links == true">
                    <h3 class="m-0 pb-2" [ngStyle]="{'color': getAllFooterData.footertextcolor, 'font-size':getAllFooterData.fontSize, 'font-weight': getAllFooterData.fontWeight}">LINKS
                    </h3>
                    <ul>
                        <li class="" [ngStyle]="{'color': getAllFooterData.footertextcolor,'font-size':getAllFooterData.fontSize, 'font-weight': getAllFooterData.fontWeight}"><a>Home</a>
                        </li>
                        <li class="" [ngStyle]="{'color': getAllFooterData.footertextcolor,'font-size':getAllFooterData.fontSize, 'font-weight': getAllFooterData.fontWeight}"><a>About
                                Us</a></li>
                        <li class="" [ngStyle]="{'color': getAllFooterData.footertextcolor,'font-size':getAllFooterData.fontSize, 'font-weight': getAllFooterData.fontWeight}"><a>Contact
                                Us</a></li>
                    </ul>
                </div>
                <div class="policies col-lg-2" *ngIf="privacy == true">
                    <h3 class="m-0 pb-2" [ngStyle]="{'color': getAllFooterData.footertextcolor, 'font-size':'20px', 'font-weight': '500'}">POLICIES
                    </h3>
                    <ul>
                        <li class="" [ngStyle]="{'color': getAllFooterData.footertextcolor,'font-size':getAllFooterData.fontSize, 'font-weight': getAllFooterData.fontWeight}">
                            <a>Return Policy</a>
                        </li>
                        <li class="" [ngStyle]="{'color': getAllFooterData.footertextcolor,'font-size':getAllFooterData.fontSize, 'font-weight': getAllFooterData.fontWeight}">
                            <a>Terms and Conditions</a>
                        </li>
                        <li class="" [ngStyle]="{'color': getAllFooterData.footertextcolor,'font-size':getAllFooterData.fontSize, 'font-weight': getAllFooterData.fontWeight}">
                            <a>Privacy Policy</a>
                        </li>
                        <li class="" [ngStyle]="{'color': getAllFooterData.footertextcolor,'font-size':getAllFooterData.fontSize, 'font-weight': getAllFooterData.fontWeight}">
                            <a>Shipping</a>
                        </li>
                    </ul>
                </div>
                <div class="contact col-lg-5 d-flex justify-content-center" *ngIf="contact == true">
                    <div>
                        <h3 class="m-0 pb-2" [ngStyle]="{'color': getAllFooterData.footertextcolor, 'font-size':'20px', 'font-weight': '500'}">
                            CONTACT</h3>
                        <div class="mb-1 gap-2 d-flex">
                            <mat-icon>call</mat-icon>
                            <p class="" [ngStyle]="{'color': getAllFooterData.footertextcolor,'font-size':getAllFooterData.fontSize, 'font-weight': getAllFooterData.fontWeight}">
                                {{brandDetails.phoneNumber}}
                            </p>
                        </div>
                        <div class="mb-1 gap-2 d-flex">
                            <mat-icon>email</mat-icon>
                            <p class="" [ngStyle]="{'color': getAllFooterData.footertextcolor,'font-size':getAllFooterData.fontSize, 'font-weight': getAllFooterData.fontWeight}">
                                {{brandDetails.email}}
                            </p>
                        </div>
                        <div class="mb-1 gap-2 d-flex">
                            <mat-icon>location_on</mat-icon>
                            <p class="" [ngStyle]="{'color': getAllFooterData.footertextcolor,'font-size':getAllFooterData.fontSize, 'font-weight': getAllFooterData.fontWeight}">{{brandDetails.address ? brandDetails.address : 'No data'}}
                            </p>
                        </div>
                        <div class="mb-1 gap-2 d-flex cursor" [routerLink]="['/delete-profile']">
                            <p class="" [ngStyle]="{'color': getAllFooterData.footertextcolor,'font-size':getAllFooterData.fontSize, 'font-weight': getAllFooterData.fontWeight}">
                                Delete Account </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="right col-lg-3 d-flex flex-column gap-4" *ngIf="footerAlignment == 'right'">
                <div *ngIf="social == true">
                    <img src="../../../../assets/images/default2.jpg" alt="App Logo">
                </div>
               
                <div class="social d-flex gap-2" *ngIf="social == true">
                    <a [ngStyle]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-facebook"></i></a>
                    <a [ngStyle]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-whatsapp"></i></a>
                    <a [ngStyle]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-pinterest"></i></a>
                    <a [ngStyle]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-instagram"></i></a>
                    <a [ngStyle]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-youtube"></i></a>
                    <a [ngStyle]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-linkedin"></i></a>
                </div>
            </div>

        </div>

        <hr class="" [ngStyle]="{'border-top': '2px solid' + getAllFooterData.footertextcolor,}">

        <div class="dual-footer">
            <div class="d-flex justify-content-start">
                <img *ngIf="payment == 'left'" src="../../../../../assets/images/payment-method_69e7ec.png" alt=""
                    width="50%">
            </div>
            <div class="d-flex justify-content-center">
                <img *ngIf="payment == 'center'" src="../../../../../assets/images/payment-method_69e7ec.png" alt=""
                    width="50%">
            </div>
            <div class="d-flex justify-content-end">
                <img *ngIf="payment == 'end'" src="../../../../../assets/images/payment-method_69e7ec.png" alt=""
                    width="50%">
            </div>
        </div>
    </div>
</div> -->


<div class="footer-clean" *ngIf="getAllFooterData"
    [ngStyle]="{'padding-top':getAllFooterData.topPadding + 'px', 'padding-bottom':getAllFooterData.bottamPadding + 'px' , 'background-color': getAllFooterData.footerbackgroundcolor, 'color':getAllFooterData.footertextcolor}"
    [ngClass]="getAllFooterData.content">
    <footer>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-sm-4 col-md-3 item">
                    <h3>LINKS</h3>
                    <ul [ngStyle]="{'color': getAllFooterData.footertextcolor, 'font-size':getAllFooterData.fontSize, 'font-weight': getAllFooterData.fontWeight}">
                        <li><a [routerLink]="['']">Home</a></li>
                        <li><a [routerLink]="['/about-us']">About Us</a></li>
                        <li><a [routerLink]="['/contact-us']">Contact Us</a></li>
                    </ul>
                </div>
                <div class="col-sm-4 col-md-3 item">
                    <h3>POLICIES</h3>
                    <ul [ngStyle]="{'color': getAllFooterData.footertextcolor, 'font-size':getAllFooterData.fontSize, 'font-weight': getAllFooterData.fontWeight}">
                        <li><a [routerLink]="['/return-policy']">Return policy</a></li>
                        <li><a [routerLink]="['/terms-of-use']">Terms and conditions</a></li>
                        <li><a [routerLink]="['/privacy']">Privacy policy</a></li>
                        <li><a [routerLink]="['/shipping']">Shipping</a></li>
                    </ul>
                </div>
                <div class="col-sm-4 col-md-3 item">
                    <h3>CONTACT</h3>
                    <ul [ngStyle]="{'color': getAllFooterData.footertextcolor, 'font-size':getAllFooterData.fontSize, 'font-weight': getAllFooterData.fontWeight}">
                        <li><a>{{brandDetails.phoneNumber}}</a></li>
                        <li><a>{{brandDetails.email}}</a></li>
                        <li><a>{{brandDetails.address ? brandDetails.address : 'No data'}}</a></li>
                        <!-- <li class="cursor" [routerLink]="['/delete-profile']">Delete Profile</li> -->
                    </ul>
                </div>
                <div class="col-lg-3 item social" *ngIf="socialMediaLinks">
                    <a [href]='socialMediaLinks[0]?.faceBook' target="_blank"
                    [style]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-facebook"></i></a>
                    <a [href]='socialMediaLinks[0]?.whatsApp' target="_blank"
                    [style]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-whatsapp"></i></a>
                    <a [href]='socialMediaLinks[0]?.pinterest' target="_blank"
                    [style]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-pinterest"></i></a>
                    <!-- <a [href]='socialMediaLinks[0]?.faceBook' target="_blank"
                    [style]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-instagram"></i></a> -->
                    <a [href]='socialMediaLinks[0]?.youTube' target="_blank"
                    [style]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-youtube"></i></a>
                    <a [href]='socialMediaLinks[0]?.linkdIn' target="_blank"
                    [style]="{'color':getAllFooterData.footertextcolor, 'border':'1px solid' + getAllFooterData.footertextcolor}"><i class="bi bi-linkedin"></i></a>
                    <p class="copyright">YONEAK © 2024 {{ecomAppDetails.appName}}</p>
                </div>
            </div>
        </div>
        <mat-divider class="mt-2"
            [ngStyle]="{'border-top': appData.border+ecomAppDetails.headerTextColor+appData.borderOpacity}"></mat-divider>
        <div class="w-100 d-flex justify-content-center">
      
        </div>
    </footer>
</div>