/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MyVouchers { 
    voucherId?: number;
    brandName?: string | null;
    brandLogo?: string | null;
    name?: string | null;
    code?: string | null;
    minPrice?: number | null;
    maxDiscount?: number | null;
    discountPercentage?: number;
    expiresOn?: string | null;
    description?: string | null;
    guideLines?: string | null;
    isFlatDiscount?: boolean;
    brandId?: number;
    isRevealed?: boolean;
    isUsed?: boolean;
    image?: string | null;
}

