<div class="flex-column"
    [ngStyle]="{'background-color': allPageColors?.pageBackgroundColor, 'color': allPageColors?.pageTextColor}" style="min-height:100vh">
    <div class="header link d-flex align-items-center gap-1" 
        [ngStyle]="{'background-color': headerStyles?.headerBackgroundColor, 'color': headerStyles?.headerTextColor}"
        (click)="navigateToProfileOverview()">
        <mat-icon>person</mat-icon>
        <span>{{isAuthenticated ? userDetails?.userFullName : 'Login & Signup'}}</span>
    </div>
    <div class="menus link d-flex align-items-center  gap-1"  (click)="navigateToAllCategories()">
        <mat-icon>dashboard</mat-icon>
        <span class="fw-500">All Categories</span>
    </div>
    <div class="menus link d-flex align-items-center  gap-1"  (click)="otherProducts('none-categories')">
        <mat-icon>loyalty</mat-icon>
        <span class="fw-500">{{nonCategoryName.modelName}}</span>
    </div>
    <mat-divider [ngStyle]="{'border-top': appData.border+allPageColors?.pageTextColor+appData.borderOpacity}"></mat-divider>

    <div class="menus link d-flex align-items-center gap-1"  (click)="navigateToOrders()">
        <mat-icon>shopping_basket</mat-icon>
        <span class="fw-500">My Orders</span>
    </div>
    <div class="menus link d-flex align-items-center gap-1" (click)="navigateToCart()">
        <mat-icon>shopping_cart</mat-icon>
        <span class="fw-500">My Cart</span>
    </div>
    <div class="menus link d-flex align-items-center gap-1"  (click)="navigateToWishList()">
        <mat-icon>favorite_border</mat-icon>
        <span class="fw-500">My Wishlist</span>
    </div>

    <mat-divider [ngStyle]="{'border-top': appData.border+allPageColors?.pageTextColor+appData.borderOpacity}"></mat-divider>
    <div class="menus link d-flex align-items-center gap-1"  (click)="navigateToProfile()">
        <mat-icon>person</mat-icon>
        <span class="fw-500">Profile</span>
    </div>
    <div class="menus link d-flex align-items-center gap-1"  (click)="navigateToEditProfile()">
        <mat-icon>person</mat-icon>
        <span class="fw-500">Edit Profile</span>
    </div>
    <div class="menus link d-flex align-items-center gap-1"  (click)="navigateToUpdatePassword()">
        <mat-icon>person</mat-icon>
        <span class="fw-500">Update Password</span>
    </div>
    <div class="menus link d-flex align-items-center gap-1"  (click)="navigateToAddresses()">
        <mat-icon>my_location</mat-icon>
        <span class="fw-500">Saved Addresses</span>
    </div>
    <div class="menus link d-flex align-items-center gap-1" (click)="navigateToWallet()">
        <mat-icon>account_balance_wallet</mat-icon>
        <span class="fw-500">My Wallet</span>
    </div>
    <div class="menus link d-flex align-items-center gap-1"  (click)="navigateToVouchers()">
        <mat-icon>local_offer</mat-icon>
        <span class="fw-500">My Vouchers</span>
    </div>
    <div class="menus link d-flex align-items-center gap-1"  *ngIf="isAuthenticated" (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span class="fw-500">Logout</span>
    </div>

</div>
