/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AuthData { 
    accessToken?: string | null;
    name?: string | null;
    email?: string | null;
    image?: string | null;
    userID?: number;
    isAdmin?: boolean;
    role?: string | null;
    roles?: Array<string> | null;
    brandid?: number;
    outletid?: Array<number> | null;
    isEcomYN?: boolean;
    isReseller?: boolean;
    expiresOn?: string;
}

