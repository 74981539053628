/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EcomShipping { 
    fK_Order_Id?: number | null;
    shipment_ID?: number;
    fK_OrderItem_Id?: number | null;
    fK_Product_Id?: number | null;
    fK_AppUser_Id?: number | null;
    shipment_Status?: string | null;
    created_Date?: string;
    fullName?: string | null;
    shipping_Address?: string | null;
    email_Address?: string | null;
    phoneNos?: string | null;
    city?: string | null;
    postCode?: string | null;
}

