/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WebSettingModel { 
    id?: number;
    pageBackgroundColor?: string | null;
    pageTextColor?: string | null;
    buttonBackgroundColor?: string | null;
    buttonTextColor?: string | null;
    cardBackground?: string | null;
    cardTextColor?: string | null;
    favicon?: string | null;
    websiteName?: string | null;
    fontStyle?: string | null;
    brandId?: number | null;
    autorotate?: boolean | null;
    ogTitle?: string | null;
    ogDescription?: string | null;
    ogImage?: string | null;
    ogUrl?: string | null;
}

