import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'neglectSCPipe'
})
export class neglectSCPipe implements PipeTransform {

    transform(value: string): string {
        let newVal = '#'+(value.replace(/[^\w\s]/gi, ''));
        return newVal;
      }

}