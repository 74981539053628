<div #hederheight *ngIf="headerStyle.dropDownStyle == '3'" style="position: relative; z-index: 100;"
    [ngStyle]="{'background-color': headerBackgroundColor}">
    <div class="container p-2 d-md-block d-none desktopCtegories">
        <div class="" *ngIf="categories && categories.length > 0" style="display: flex; justify-content: space-around; align-items: center;">
            <div *ngFor="let item of categories.slice(0, categories.length > 8 ? 8 : categories.length)"
                style="width: 15%;">
                <div class="link"
                    (click)="headerStyle.dropDownStyle != '3' && headerStyle.solidHeader == 'Solid'  ?  (item.categoryName !== 'OTHERS' ? viewProductSubCategories(item) : viewProductSubCategories('none-categories')) : ''">
                    <div style="display: flex; justify-content: center;" class="mediaimg pt-3" *ngIf=" item.categoryName !== 'OTHERS' && headerStyle.showAllCategory && shouldShowImages(item)">
                        <img 
                            [src]="item?.path ? blobStoragePath + item?.path : '../../../../assets/images/default.jpg'"
                            alt="category image" class="link br-50"
                            (click)="item.categoryName !== 'OTHERS' ? viewProductSubCategories(item) : viewProductSubCategories('none-categories');">

                        <!-- <img *ngIf=" item.name === 'OTHERS' && isHomePage && !ecomAppSettings?.isCategoryIconHidden && headerStyle.showAllCategory"
                            [src]="item?.path ? item?.path : '../../../../assets/images/default.jpg'"
                            alt="category image" class="link bg-white br-50"
                            (click)="item.name !== 'OTHERS' ? viewProductSubCategories(item) : viewProductSubCategories('none-categories')"> -->
                    </div>
                    <div style="display: flex; justify-content: center;" *ngIf="headerStyle.dropDownStyle == '3'">
                        <div class=" menu-gold d-flex">
                            <ul class="menu" *ngIf="shouldShowMenu()">
                                <li (mouseenter)="getAllSubCategories(item)">
                                    <a (click)="item.categoryName !== 'OTHERS' ? viewProductSubCategories(item) : viewProductSubCategories('none-categories')"><span class=" t-a-c text-ellipsis fw-500"
                                            [ngStyle]="{'color':headerStyle.headerTextColor, 'font-weight': headerStyle.fontWeight, 'font-size': '14px'}"
                                            [ngClass]="headerStyle.fontStyle">{{item?.categoryName |
                                            uppercase}}</span></a>
                                    <ul class="mt-2"
                                        [ngStyle]="{'background-color':headerBackgroundColor,'color':headerStyle.headerTextColor,'font-weight': headerStyle.fontWeight, 'font-size': '14px'}"
                                        [ngClass]="headerStyle.fontStyle">
                                        <li class="subli text-ellipsis fw-500"
                                            *ngFor="let subCategory of item.subCategoryModel"
                                            (click)="viewProducts(subCategory,item,'')"
                                            style="border-bottom: 1px solid white;
                                            border-top:1px solid rgba(255, 255, 255, 0.466);
                                            border-left:1px solid rgba(255, 255, 255, 0.466) ;
                                            border-right: 1px solid rgba(255, 255, 255, 0.466);">
                                            <a>{{subCategory.subCategoryName| uppercase}}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <!-- <span class="fs-x-sm t-a-c text-ellipsis fw-500"
                            [ngStyle]="{'color':headerStyle.headerTextColor}">{{item?.name}}</span> -->
                    </div>
                </div>
            </div>

            <div *ngIf="categories.length > 7" style="width: 15%;">
                <div class="link" (click)="viewAll()" *ngIf="shouldShowMenu()">
                    <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 10px;margin-top: 10px;" class="mediaimg"
                        *ngIf="!ecomAppSettings?.isCategoryIconHidden && headerStyle.showAllCategory">
                        <img *ngIf="shouldShowImagess()" src="../../../../../assets/images/menu.png" class="img-fluid rounded-circle"
                            [ngStyle]="{'border': borderUi}">
                    </div>
                    <div style="display: flex; justify-content: center; align-items: center;">
                        <span class=" t-a-c t-tr-c fw-500"
                            [ngStyle]="{'background-color':headerBackgroundColor,'color':headerStyle.headerTextColor,'font-weight': headerStyle.fontWeight, 
                            'font-size': '14px'}" [ngClass]="headerStyle.fontStyle">{{'ViewAll'
                            | uppercase}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-div-pad-cat mat-elevation-z1 category-slider d-md-none" *ngIf="categories">
        <div *ngIf="categories?.length && categories.length < 5" class="" style="display: flex; justify-content: space-evenly;">
            <div class="" *ngFor="let item of categories">
                <div class="d-flex flex-column align-items-center" style="cursor: pointer;"
                    (click)="item.categoryName !== 'OTHERS' ? viewProductSubCategories(item) : viewProductSubCategories('none-categories')">
                    <img class="mediaimg" *ngIf="item.categoryName !== 'OTHERS' && headerStyle.showAllCategory"
                        [src]="item?.path ? blobStoragePath + item?.path : '../../../../assets/images/default.jpg'"
                        alt="category image" width="40px" height="40px" class="bg-white rounded-circle">
                    <img class="mediaimg" *ngIf="item.categoryName === 'OTHERS' && headerStyle.showAllCategory" [src]="item?.path "
                        alt="category image" width="40px" height="40px" class="bg-white rounded-circle">
                    <span class="fs-x-sm t-a-c text-ellipsis fw-500"
                        [ngStyle]="{'background-color':headerBackgroundColor,'color':headerStyle.headerTextColor}">{{item?.categoryName}}</span>
                </div>
            </div>
        </div>

        <!-- <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="1%" *ngIf="productCategories?.length && productCategories.length < 6">
            <div fxFlex="19%"  fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px"
            *ngFor="let item of productCategories.slice(0, productCategories.length > 7 ? 7 : productCategories.length)"
                class="link" (click)="viewProductSubCategories(item)">
                <img [src]="item?.path? blobStoragePath+item?.path : '../../../../assets/images/default.jpg'"
                    alt="category image" width="50px" height="50px"
                     class="link bg-white br-50">
                <span class="fs-x-sm t-a-c text-ellipsis fw-500">{{item?.name}}</span>
            </div>
        </div> -->

        <owl-carousel-o [options]="customOptions" *ngIf="categories?.length && categories.length > 4">
            <ng-container *ngFor="let item of categories">
                <ng-template carouselSlide aria-label="categories slider">
                    <div [ngClass]="{'special-category': item.categoryName === 'OTHERS'}">

                        <!-- Image rendering based on category -->
                        <div class="mediaimg">
                            <img *ngIf="isHomePage && !ecomAppSettings?.isCategoryIconHidden && item.categoryName !== 'OTHERS' && headerStyle.showAllCategory"
                                [src]="item?.path ? blobStoragePath + item?.path : '../../../../assets/images/default.jpg'"
                                width="100%" height="100%" alt="category image" class="link bg-white br-50"
                                (click)="item.categoryName !== 'OTHERS' ? viewProductSubCategories(item) : viewProductSubCategories('none-categories')">

                            <img *ngIf="isHomePage && !ecomAppSettings?.isCategoryIconHidden && item.categoryName === 'OTHERS' && headerStyle.showAllCategory"
                                [src]="item?.path ? item?.path : '../../../../assets/images/default.jpg'" width="100%"
                                height="100%" alt="category image" class="link bg-white br-50"
                                (click)="item.categoryName !== 'OTHERS' ? viewProductSubCategories(item) : viewProductSubCategories('none-categories')">
                        </div>

                        <!-- Click event based on category name -->
                        <div class="d-flex justify-content-center">
                            <div class=" menu-gold d-flex p-2">
                                <ul class="menu">
                                    <li (mouseenter)="getAllSubCategories(item.id)">
                                        <a (click)="item.categoryName !== 'OTHERS' ? viewProductSubCategories(item) : viewProductSubCategories('none-categories')"><span class="fs-x-sm t-a-c text-ellipsis fw-500" style="width: 100%;text-overflow: ellipsis;white-space: nowrap;"
                                                [ngStyle]="{'color':headerStyle.headerTextColor,'font-weight': headerStyle.fontWeight, 'font-size': '14px'}"
                                                [ngClass]="headerStyle.fontStyle">{{item?.categoryName |
                                                uppercase}}</span></a>
                                        <ul class="mt-2"
                                            [ngStyle]="{'background-color':headerBackgroundColor,'color':headerStyle.headerTextColor,'font-weight': headerStyle.fontWeight, 'font-size': '14px'}"
                                            [ngClass]="headerStyle.fontStyle">
                                            <li class="p-2 subli" *ngFor="let subCategory of item.subCategoryModel"
                                                (click)="viewProducts(item,'','')"
                                                style="border-bottom: 1px solid white;border-top:1px solid rgba(255, 255, 255, 0.466) ;border-left:1px solid rgba(255, 255, 255, 0.466) ;border-right: 1px solid rgba(255, 255, 255, 0.466);">
                                                <a>{{subCategory.subCategoryName | uppercase}}</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <!-- <span class="fs-x-sm t-a-c text-ellipsis fw-500"
                                (click)="item.name !== 'OTHERS' ? viewProductSubCategories(item) : viewProductSubCategories('none-categories')">
                                {{item?.name}}
                            </span> -->
                        </div>

                    </div>
                </ng-template>
            </ng-container>


        </owl-carousel-o>
    </div>
</div>

<mat-card class="p-3" *ngIf="headerStyle.dropDownStyle == '4'" [ngStyle]="{'position':'fixed','top':headerStyle.headerHight,'width':'100%','z-index':'10000',
    'background-color': headerBackgroundColor, 'color':headerStyle.headerTextColor}">
    <div class="" style="display: flex;">
        <div class="p-4  headerCategory" style="height: 200px; overflow-y: scroll;">
            <div class="row mt-2" *ngFor="let item of categories" matTooltip="{{item?.categoryName}}"
                matTooltipPosition="right" (click)="getAllSubCategories(item.subCategoryModel)">
                <div class="col-6" style="display: flex;align-items: center;"
                    (click)="getAllSubCategories(item.subCategoryModel)"  (click)="getCtegoryId(item)">
                    <img *ngIf="!ecomAppSettings?.isCategoryIconHidden && item.categoryName !== 'OTHERS'"
                        [src]="item?.path ? blobStoragePath + item?.path : '../../../../assets/images/default.jpg'"
                        width="100%" height="100%" alt="category image" class="link bg-white">

                    <!-- <img *ngIf="!ecomAppSettings?.isCategoryIconHidden && item.categoryName === 'OTHERS'"
                        [src]="item?.path ? item?.path : '../../../../assets/images/default.jpg'" width="100%"
                        height="100%" alt="category image" class="link bg-white"
                        (click)="getAllSubCategories(item.subCategoryModel)"> -->
                </div>
                <div class="col-6" style="display: flex;align-items: center;">
                    <p class="fs-x-sm t-a-c text-ellipsis fw-500">{{item?.categoryName|
                        uppercase}}</p>
                </div>
            </div>
        </div>

        <div class="p-4 headerSubCategory" style="height: 200px; overflow-y: scroll;">
            <div class="row">
                <div class="col-4 col-md-3 col-lg-2" *ngFor="let item of productSubCategories">
                    <div>
                        <img *ngIf=" item.subCategoryName !== 'OTHERS'" [src]="blobStoragePath +item?.subCategoryPath"
                            alt="sub-category image" width="100%" height="100%" class="br-5 link bg-white box-shadow"
                            (click)="viewProductsDropDownFour(item)">
                        <img *ngIf=" item.subCategoryName === 'OTHERS'" [src]="item?.subCategoryPath"
                            alt="sub-category image" width="100%" height="100%" class="br-5 link bg-white box-shadow"
                            (click)="viewProductsDropDownFour(item)">
                    </div>
                    <div class="p-10" *ngIf=" item.subCategoryName !== 'OTHERS'"
                        (click)="viewProductsDropDownFour(item)">
                        <p class="fs-x-sm t-a-c text-ellipsis-2 link fw-500">
                            {{item?.subCategoryName | uppercase}}
                        </p>
                    </div>
                    <div class="p-10" *ngIf=" item.subCategoryName === 'OTHERS'"
                        (click)="viewProducts(item,'none-categories','')">
                        <span fxFlexAlign="center" class="fs-x-sm t-a-c text-ellipsis-2 link fw-500">
                            {{item?.subCategoryName}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

</mat-card>