/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MultipleColorsModel } from './multipleColorsModel';
import { MultipleBrandsModel } from './multipleBrandsModel';
import { SortFilterModel } from './sortFilterModel';
import { MultipleRangeModel } from './multipleRangeModel';
import { MultipleDiscountModel } from './multipleDiscountModel';


export interface BaseProductFilterModel { 
    multipleBrandsModel?: Array<MultipleBrandsModel> | null;
    multipleRangeModel?: Array<MultipleRangeModel> | null;
    multipleColorsModel?: Array<MultipleColorsModel> | null;
    multipleDiscountModel?: Array<MultipleDiscountModel> | null;
    cityId?: number | null;
    searchString?: string | null;
    brandId?: number;
    isGlobal?: boolean;
    isvisible?: boolean;
    categoryId?: number;
    subCategoryId?: number;
    baseProductId?: number | null;
    pageIndex?: number;
    pageSize?: number;
    sortFilterModel?: SortFilterModel;
}

