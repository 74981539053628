/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EcomOrders } from './ecomOrders';
import { EcomOrdersItems } from './ecomOrdersItems';


export interface EcomOrderItemsViewModel { 
    ecomorder?: EcomOrders;
    ecomitemDetails?: Array<EcomOrdersItems> | null;
    partnerId?: number | null;
    delivaryDate?: string | null;
    razorAccess?: string | null;
    razorSecret?: string | null;
    isCod?: boolean;
    shippingCharges?: number;
    isShiprocket?: boolean;
}

