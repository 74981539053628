/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EcomProductBrandForBaseProduct } from './ecomProductBrandForBaseProduct';


export interface SelectProductMob { 
    name?: string | null;
    value?: number;
    path?: string | null;
    isVisible?: boolean | null;
    subCategoryCount?: number;
    categoryId?: number | null;
    categoryMasterId?: number | null;
    subcategoryMasterId?: number | null;
    subcategoryId?: number | null;
    categoryName?: string | null;
    brandList?: Array<EcomProductBrandForBaseProduct> | null;
    productCount?: number;
    subCategory?: Array<SelectProductMob> | null;
}

