<div [ngClass]="allPageColors.fontStyle" *ngIf="allPageColors && headerStyles">
    <!-- <div class="outlet-100"  *ngIf="!ecomAppDetails">
        <div class="cssload-box-loading">
        </div>
        <span class="fs-md error-msg fw-500">{{errorMessage}}</span>
    </div> -->
    <mat-sidenav-container>
        <mat-sidenav #drawer mode="over" fixedInViewport style="width:80%; height:100vh">
            <app-side-navigation [allPageColors]="allPageColors" [headerStyles]="headerStyles"
                (toggle)="drawer.toggle()"></app-side-navigation>
        </mat-sidenav>
        <mat-sidenav-content>
            <!-- <app-navigation [ecomAppDetails]="ecomAppDetails" *ngIf="ecomAppDetails?.structure_Id === 0" (toggle)="drawer.toggle()"></app-navigation> -->
            <!-- <app-navigation-two [ecomAppDetails]="ecomAppDetails" *ngIf="ecomAppDetails?.structure_Id === 1" (toggle)="drawer.toggle()"></app-navigation-two> -->
            <!-- <app-navigation-three [ecomAppDetails]="ecomAppDetails" *ngIf="ecomAppDetails?.structure_Id === 2" (toggle)="drawer.toggle()"></app-navigation-three> -->
            <app-header (toggle)="drawer.toggle()"></app-header>
            <div [ngStyle]="{'min-height':'100vh','background-color': allPageColors.pageBackgroundColor, 'color': allPageColors.pageTextColor,
            'padding-top': headerStyles.solidHeader == 'Solid' && headerStyles.dropDownStyle == '3' ? (+headerStyles.headerHight! + subcategoriesHeight + 'px') : isHomePage ? (windowWidth > 991 ? '' : headerStyles.headerHight + 'px'  )  : headerStyles.dropDownStyle == '3' ? (+headerStyles.headerHight! + subcategoriesHeight + 'px') : headerStyles.headerHight + 'px' }">
                <router-outlet #outlet="outlet"></router-outlet>

            </div>
            <app-footer-main *ngIf="!specialPage"></app-footer-main>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>