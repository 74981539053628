import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';
import { AppThemeModule } from 'src/app/app-theme.module';
import { HeaderCategoriesModule } from '../shared/header-categories/header-categories.module';
import { MatBadgeModule } from '@angular/material/badge';
import { HeaderComponent } from './header/header.component';
import { FooterMainComponent } from './footer-main/footer-main.component';
import { MatTabsModule } from '@angular/material/tabs';



@NgModule({
  declarations: [
    SideNavigationComponent,
    HeaderComponent,
    FooterMainComponent
  ],
  imports: [
    CommonModule,
    AppThemeModule,
    HeaderCategoriesModule,
    MatBadgeModule,
    MatTabsModule,
  ],
  exports: [
    SideNavigationComponent,
    HeaderComponent,
    FooterMainComponent

  ]
})
export class MainModule { }
