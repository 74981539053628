/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CurrentUser { 
    isActive?: number;
    brandid?: number;
    userID?: number;
    email?: string | null;
    password?: string | null;
    role?: string | null;
    fullName?: string | null;
    gender?: string | null;
    mobile?: string | null;
    dpPath?: string | null;
}

