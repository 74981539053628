export * from './career.service';
import { CareerService } from './career.service';
export * from './ecomCoupons.service';
import { EcomCouponsService } from './ecomCoupons.service';
export * from './ecomMobApp.service';
import { EcomMobAppService } from './ecomMobApp.service';
export * from './ecomMobAuth.service';
import { EcomMobAuthService } from './ecomMobAuth.service';
export * from './ecomOrders.service';
import { EcomOrdersService } from './ecomOrders.service';
export * from './ecomVoucher.service';
import { EcomVoucherService } from './ecomVoucher.service';
export * from './ecomWallet.service';
import { EcomWalletService } from './ecomWallet.service';
export * from './ecomWebAuth.service';
import { EcomWebAuthService } from './ecomWebAuth.service';
export * from './shiprocketCommon.service';
import { ShiprocketCommonService } from './shiprocketCommon.service';
export * from './shiprocketOrders.service';
import { ShiprocketOrdersService } from './shiprocketOrders.service';
export const APIS = [CareerService, EcomCouponsService, EcomMobAppService, EcomMobAuthService, EcomOrdersService, EcomVoucherService, EcomWalletService, EcomWebAuthService, ShiprocketCommonService, ShiprocketOrdersService];
