import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AccountService } from '../_services/account.service';
import { constant } from '../constants';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    token!: string;

    constructor(private accountService: AccountService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        this.token = this.accountService.storage?.getItem(constant.authData.access_token) || ''; 
          if(!this.token)
            return next.handle(request);
        const modifiedReq = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + this.token
                }
            });
        return next.handle(modifiedReq);
    }
}
