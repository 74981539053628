/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EcomOrdersItems { 
    productColor?: string | null;
    productSize?: string | null;
    orderItemsID?: number | null;
    fkProductId?: number | null;
    baseProductId?: number | null;
    fkOrderId?: number | null;
    orderPrice?: number | null;
    productDiscount?: number | null;
    orderQuantity?: number | null;
    orderTax?: number | null;
    orderDate?: string | null;
    productName?: string | null;
    photoPath?: string | null;
    isFreeDelivery?: number | null;
    percentDiscount?: string | null;
    shippingCharge?: number | null;
    isDeleted?: boolean | null;
    isReturnRequested?: boolean | null;
    returnOrderStatus?: number | null;
    returnOrderId?: number | null;
    isShipRocket?: boolean | null;
}

