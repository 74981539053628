import { Component, Input } from '@angular/core';
import { BrandDetailsForBrandModel, EcomBrandwiseApkThemeData, EcomMobAuthService, EcomWebAuthService } from 'build/openapi';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-main',
  templateUrl: './footer-main.component.html',
  styleUrls: ['./footer-main.component.scss']
})
export class FooterMainComponent {

  @Input('ecomAppDetails')
  ecomAppDetails: EcomBrandwiseApkThemeData = {};
  svgColorFilterString!: string;

  getAllFooterData: any

  get appData() {
    return environment;
  }
  links: boolean = true;
  privacy: boolean = true;
  contact: boolean = true;
  social: boolean = true;
  payment = 'center';
  footerAlignment = 'right'

  brandDetails: BrandDetailsForBrandModel = {};
  phoneNumber!: string;
  email!: string;

  socialMediaLinks: any;

  constructor(
    private eComWebAuthService: EcomWebAuthService,
    private ecomMobAuthService: EcomMobAuthService,
  ) { }

  ngOnInit() {

    this.ecomMobAuthService.apiEcomMobAuthGetBrandContactDetailsGet(this.appData.brandId).subscribe(res => {
      this.brandDetails = res;

      this.phoneNumber = 'tel:+91' + res.phoneNumber;
      this.email = 'mailto:' + res.email;
    })

    if (this.ecomAppDetails?.headerTextColor) {
      this.svgColorFilterString = this.hexToCSSFilter(this.ecomAppDetails.headerTextColor);

    }

    this.getFooterData()
    this.getSocialMediaLinks()
  }

  hexToCSSFilter(hexColor: string): string {
    // Remove '#' from the hexadecimal color code if present
    hexColor = hexColor.replace('#', '');

    // Convert the hexadecimal color code to RGB components
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Normalize RGB values to be in the range of 0 to 1
    const normalizedR = r / 255;
    const normalizedG = g / 255;
    const normalizedB = b / 255;

    // Calculate CSS filter values
    const invertValue = 100; // 100% for full invert
    const sepiaValue = (normalizedR * 0.393) + (normalizedG * 0.769) + (normalizedB * 0.189);
    const hueRotateValue = 180; // 180deg for hue-rotate

    // Assemble the CSS filter string
    const cssFilter = `invert(${invertValue}%) sepia(${sepiaValue * 100}%) hue-rotate(${hueRotateValue}deg)`;

    return cssFilter;
  }

  getFooterData() {
    this.eComWebAuthService.apiEcomWebAuthGetLatestDynamicFooterByBrandIdGet(this.appData.brandId).subscribe(res => {
      if (res.data) {
        this.getAllFooterData = res.data;
     
      }
    })
  }

  getSocialMediaLinks() {
    this.ecomMobAuthService.apiEcomMobAuthGetSocialMediaLinksByBrandIdGet(this.appData.brandId).subscribe((res) => {
      if (res.data) {
        this.socialMediaLinks = res.data

      }
    })
  }

}
