/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EcommerceCategoryModel { 
    id?: number | null;
    fK_Brandid?: number | null;
    fK_Id?: number | null;
    name?: string | null;
    path?: string | null;
    productImages?: Array<string> | null;
    imagePath?: Array<string> | null;
    mobilePath?: string | null;
    yn?: boolean;
    createdDate?: string | null;
    isUrl?: boolean;
    categoryId?: number | null;
    fkCategoryIdMasterId?: number | null;
    fkSubCategoryIdMasterId?: number | null;
    categoryName?: string | null;
    subCategoryId?: number | null;
    subCategoryName?: string | null;
    isCategory?: boolean;
    isProduct?: boolean;
    isFavorite?: number;
    baseProductId?: number;
    isProductPresent?: boolean | null;
    sp?: number;
    mrp?: number;
    banner_Content_Title?: string | null;
    banner_Content?: string | null;
    bannerContentColor?: string | null;
    is_Banner_Content?: boolean | null;
    banner_Content_Alignment?: string | null;
    button_Background_Color?: string | null;
    button_Name?: string | null;
    button_Color?: string | null;
    title_Colour?: string | null;
    animation_Type?: string | null;
    banner_Content_Title_Color?: string | null;
    fontStyle?: string | null;
    is_Custom_Button?: boolean | null;
    button_Type?: string | null;
    border_Radius?: string | null;
    top_Padding?: string | null;
    buttom_Padding?: string | null;
    left_Padding?: string | null;
    right_Padding?: string | null;
    bannerContentFontSize?: string | null;
    bannerContentFontWeight?: string | null;
    bannerContentTitleFontSize?: string | null;
    bannerContentTitleFontWeight?: string | null;
    productAnimation?: string | null;
    productInRow?: string | null;
    bannerContent3DStyle?: string | null;
    bannerContentTitleFontStyle?: string | null;
    bannerContentTitle3DStyle?: string | null;
    bannerContentVerticalAlignment?: string | null;
    bannerContentButtonFontSize?: string | null;
}

