/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EcomProductReviews } from './ecomProductReviews';
import { ProductImagePaths } from './productImagePaths';


export interface ProductSizeColorAndPrice { 
    productImagePaths?: Array<ProductImagePaths> | null;
    productId?: number;
    productColor?: string | null;
    productSize?: string | null;
    productMRP?: number;
    productSP?: number;
    productDiscount?: number;
    productDescription?: string | null;
    countryOfOrigin?: string | null;
    manufacturer?: string | null;
    quantity?: number;
    unitTypeId?: number;
    link?: string | null;
    shippingCharges?: number;
    isPercentage?: boolean;
    discount?: number;
    gst?: number;
    isVisible?: boolean;
    reviews?: Array<EcomProductReviews> | null;
    ratings?: number | null;
}

