import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderCategoriesComponent } from './header-categories.component';
import { AppThemeModule } from 'src/app/app-theme.module';
import { Router, RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    HeaderCategoriesComponent
  ],
  imports: [
    CommonModule,
    AppThemeModule,
    RouterModule.forChild([{path:'',component:HeaderCategoriesComponent}])
  ],
  exports: [HeaderCategoriesComponent]
})
export class HeaderCategoriesModule { }
