/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EcomCart { 
    userID?: number;
    productID?: number | null;
    weight?: string | null;
    shoppingCart_ID?: number;
    fK_AppUser_Id?: number | null;
    order_Date?: string;
    fK_Product_Id?: number | null;
    productName?: string | null;
    price?: number | null;
    mrp?: number | null;
    quantity?: number | null;
    photoPath?: string | null;
    isfreedelievry?: number | null;
    percentDiscount?: string | null;
    gst?: string | null;
    shipping_Charge?: number | null;
    availableItems?: number | null;
    colorCode?: string | null;
    size?: string | null;
    baseProductId?: number;
}

