/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EcomOrders { 
    orderID?: number;
    fK_AppUser_Id?: number | null;
    order_Status?: string | null;
    order_SubTotal?: number | null;
    product_Discount?: number | null;
    order_Tax?: number | null;
    order_Shipping?: number | null;
    order_Total?: number | null;
    order_Promo?: string | null;
    order_Discount?: number | null;
    order_GrandTotal?: number | null;
    order_Date?: string | null;
    fk_Shipping_id?: number | null;
    payment_Status?: string | null;
    payment_Details?: string | null;
    fk_Razor_OrderIds?: string | null;
    fk_RazorId?: string | null;
    couponId?: number;
}

