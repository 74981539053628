/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EcomOrdersNew } from './ecomOrdersNew';
import { EcomOrdersItems } from './ecomOrdersItems';


export interface EcomOrderItemsViewModelNew { 
    ecomorder?: EcomOrdersNew;
    ecomitemDetails?: Array<EcomOrdersItems> | null;
    partnerId?: number | null;
    includeWalletBalance?: boolean | null;
    delivaryDate?: string | null;
    razorAccess?: string | null;
    razorSecret?: string | null;
    isCod?: boolean;
    shippingCharges?: number;
    isShiprocket?: boolean;
    cartId?: number | null;
}

