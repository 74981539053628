/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EcomSettingsModel } from './ecomSettingsModel';


export interface EcomSettingsModelServiceResponse { 
    message?: string | null;
    statusCode?: number;
    data?: EcomSettingsModel;
}

