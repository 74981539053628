import { Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AccountService, UserDetails } from './_services/account.service';
import { DynamicHeaderModelGet, EcomBrandwiseApkThemeData, EcomMobAuthService, EcomWebAuthService, WebSettingModel } from 'build/openapi';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta, Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title!: string;
  publicIpAddress: any;
  currentDateTime!: string;
  brandId!: number;
  get appData(): any {
    return environment;
  }
  errorMessage = 'Loading, Please wait ...';
  specialPage = false;
  specialPages: string[] = [
    '/accounts/sign-in',
    '/accounts/sign-up',
    '/accounts/forgot-password',
    '/accounts/reset-password',
  ];
  isAuthenticated!: boolean;
  userDetails!: UserDetails;
  ecomAppDetails: EcomBrandwiseApkThemeData = {};
  trigger = 0;
  @ViewChild('drawer', { static: false })
  drawer!: MatDrawer;
  outletClass = "outlet-90"

  get appData1(): any {
    return environment;
  }
  isHomePage: boolean = false
  constructor(
    private ecomMobAuthService: EcomMobAuthService,
    private router: Router,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private meta: Meta,
    private titleService: Title,
    private ecomWebAuthService: EcomWebAuthService,
    @Inject(PLATFORM_ID) private platformId: Object) {


    this.router.events.pipe(
      filter((event: any): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Check if current route is homepage
      this.isHomePage = event.url === '/';
    });
    this.checkScreenSize();
    this.router.events.subscribe(data => {
      if (data instanceof NavigationEnd) {
        const url = data.urlAfterRedirects.split('?')[0];
        try {
          if (this.specialPages.findIndex(x => x === url) > -1) {
            this.specialPage = true;
          }
          else {
            this.specialPage = false;
          }
        } catch (error) {
          this.specialPage = true;
        }
      }
      else {
      }
    });
  }

  headerStyles!: DynamicHeaderModelGet
  subcategoriesHeight: any
  ngOnInit(): void {


    this.accountService.subCategoriesHeight$.subscribe((res) => {
      this.subcategoriesHeight = res

    })

    this.meta.updateTag({ property: 'og:title', content: 'fsdfeghftjygkuhlij' });
    this.meta.updateTag({ property: 'og:image', content: 'https://img.freepik.com/free-photo/cute-woman-bright-hat-purple-blouse-is-leaning-stand-with-dresses-posing-with-package-isolated-background_197531-17610.jpg' });
    this.meta.updateTag({ property: 'og:url', content: 'http://demo4.yoneak.in/' })


    const ogTitle = this.meta.getTag('property="og:title"');

    this.checkScreenSize();
    this.getDynamicHeader()
    this.getAllPageColors()

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(0, 0)
      }


    });
    this.accountService.checkLogin();

    this.ecomMobAuthService.apiEcomMobAuthGetEcomThemeColorsGet("1.0.1", this.appData.brandId).subscribe(res => {

      if (res.data != null) {
        this.ecomAppDetails = {
          headerBackgroundColor: this.transform(res.data.headerBackgroundColor || ''),
          headerTextColor: this.transform(res.data.headerTextColor || ''),
          buttonBackgroundColor: this.transform(res.data.buttonBackgroundColor || ''),
          buttonTextColor: this.transform(res.data.buttonTextColor || ''),
          pageBackgroundColor: this.transform(res.data.pageBackgroundColor || ''),
          textColor: this.transform(res.data.textColor || ''),
          topicBackgroundColor: this.transform(res.data.topicBackgroundColor || ''),
          topicTextColor: this.transform(res.data.topicTextColor || ''),
          cardBackgroundColor: this.transform(res.data.cardBackgroundColor || ''),
          cardTextColor: this.transform(res.data.cardTextColor || ''),
          appLogo: res.data.appLogo,
          appLogo1: res.data.appLogo1,
          appLogo2: res.data.appLogo2,
          appName: res.data.appName,
          fontFamily: res.data.fontFamily,
          razorKey: res.data.razorKey,
          razorSecretKey: res.data.razorSecretKey,
          isCod: res.data.isCod,
          appVersion: res.data.appVersion,
          brandId: res.data.brandId,
          shippingCharges: res.data.shippingCharges,
          hideCategory: res.data.hideCategory,
          isCategoryIconHidden: res.data.isCategoryIconHidden,
          isSubCategoryIconHidden: res.data.isSubCategoryIconHidden,
          isShiprocket: res.data.isShiprocket,
          isBrowseableWithOutLogin: res.data.isBrowseableWithOutLogin,
          isScreenshotEnabled: res.data.isScreenshotEnabled,
          awsBasePath: res.data.awsBasePath,
          structure_Id: 1,
          logoAlignment: res.data.logoAlignment,
          // logoAlignment:'Right'

        }

        this.setFavicon()

        this.accountService._ecomAppSettingDetails.next(this.ecomAppDetails);

        if (this.ecomAppDetails.structure_Id === 1) {

          if (isPlatformBrowser(this.platformId)) {
            if (window.innerWidth <= 767) {
              this.outletClass = "outlet-90"
            }
            else {
              this.outletClass = "new"
            }
          }





        }
        else {
          this.outletClass = "outlet-90"
        }



      }
      else {
        this.errorMessage = 'Please try after some time !';
      }
    }, (error: HttpErrorResponse) => {
      if (error.status === 0) {
        if (navigator.onLine) {
          this.errorMessage = 'Please try after some time !';
        }
        else {
          this.errorMessage = 'Please check your internet connection';
        }
      }
      else {
        this.errorMessage = 'Please try after some time !';
      }
    })



    this.getProductFontsize()

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  isMobile: boolean = false;
  isTab = false;
  isLaptop = false;
  windowWidth: any
  checkScreenSize() {

    if (isPlatformBrowser(this.platformId)) {
      this.windowWidth = window.innerWidth
      if (window.innerWidth <= 768) {
        this.isMobile = true
      } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
        this.isTab = true;
      } else {
        this.isLaptop = true;
      }
    }


  }
  // private setFavicon() {
  //   const link: HTMLLinkElement = document.querySelector('link[rel="icon"]') || document.createElement('link');
  //   link.rel = 'icon';
  //   link.type = 'image/x-icon';
  //   link.href = '../assets/images/cashback.png';
  //   document.head.appendChild(link);
  // }

  private setFavicon() {
    const link: HTMLLinkElement = document.querySelector('link[rel="icon"]') || document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.href = this.ecomAppDetails.awsBasePath + this.allPageColors.favicon!
    document.head.appendChild(link);


    this.titleService.setTitle(this.allPageColors.websiteName);



  }


  private setOgTags() {

  }

  transform(value: string): string {
    let newVal = '#' + (value.replace(/[^\w\s]/gi, ''));
    return newVal;
  }
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  productCardsSize: any;
  getProductFontsize() {
    this.ecomWebAuthService.apiEcomWebAuthGetProductCardSizeGet(this.appData1.brandId).subscribe(res => {
      if (res.data) {
        this.productCardsSize = res.data;
        this.accountService.productCardsSizeSource.next(this.productCardsSize)
      }
      else {
        this.productCardsSize = {
          id: 0,
          productCardHeight: '215',
          productCardImageType: 'contain',
          brandId: 0,
          prductCardBorder: true,
          productCardAnimation: 'animation3',
          productCardBackgroundColor: '#fafafa',
          productCardTextColor: 'black',
          productCardBorderRadius: 0
        }

        this.accountService.productCardsSizeSource.next(this.productCardsSize)
      }

    })
  }

  allPageColors: any
  // getAllPageColors() {

  //   this.ecomWebAuthService.apiEcomWebAuthGetWebSettingsByBrandIdGet(this.appData1.brandId).subscribe(res => {
  //     if (res.data && res.statusCode == 200) {

  //       if (res.data.length > 0) {

  //         this.allPageColors = res.data[0];

  //         this.setFavicon()
  //         this.accountService.allPageColorsSource.next(this.allPageColors)
  //       }
  //       else {
  //         this.allPageColors = {
  //           id: 3,
  //           pageBackgroundColor: "#ffffff",
  //           pageTextColor: "#00695c",
  //           buttonBackgroundColor: "#90a4ae",
  //           buttonTextColor: "#000000",
  //           cardBackground: "#455a64",
  //           cardTextColor: "#ffffff",
  //           favicon: "397/favImage/af7dc8e8-b2b3-4b97-96b6-c6ada687dc73.png",
  //           websiteName: "786 Rider Website",
  //           fontStyle: "poetsen-one-regular",
  //           brandId: 397,
  //           autorotate: false
  //         }

  //         this.accountService.allPageColorsSource.next(this.allPageColors)
  //       }
  //     }
  //     else {

  //       this.showErrorMessage(res.message || 'An error occurred while fetching the data.');
  //     }
  //   }, error => {
  //     this.showErrorMessage(error.message || 'An error occurred while fetching the data.');
  //   })

  // }
  getAllPageColors() {
    this.ecomWebAuthService.apiEcomWebAuthGetWebSettingsByBrandIdGet(this.appData1.brandId)
      .subscribe({
        next: (res) => {
          if (res.statusCode === 200 && res.data) {
            if (res.data.length > 0) {
              const apiColors = res.data[0];
              this.allPageColors = {
                id: apiColors.id ?? 3,
                pageBackgroundColor: apiColors.pageBackgroundColor ?? "#ffffff",
                pageTextColor: apiColors.pageTextColor ?? "#00695c",
                buttonBackgroundColor: apiColors.buttonBackgroundColor ?? "#90a4ae",
                buttonTextColor: apiColors.buttonTextColor ?? "#000000",
                cardBackground: apiColors.cardBackground ?? "#455a64",
                cardTextColor: apiColors.cardTextColor ?? "#ffffff",
                favicon: apiColors.favicon ?? "397/favImage/af7dc8e8-b2b3-4b97-96b6-c6ada687dc73.png",
                websiteName: apiColors.websiteName ?? "786 Rider Website",
                fontStyle: apiColors.fontStyle ?? "poetsen-one-regular",
                brandId: apiColors.brandId ?? 397,
                autorotate: apiColors.autorotate ?? false
              };
            } else {
              this.setDefaultPageColors();
            }

            this.setFavicon();
            this.accountService.allPageColorsSource.next(this.allPageColors);
          } else {
            this.handleError(res.message || 'An error occurred while fetching the data.');
          }
        },
        error: (error) => {
          this.handleError(error.message || 'An error occurred while fetching the data.');
        }
      });
  }

  private setDefaultPageColors() {
    this.allPageColors = {
      id: 3,
      pageBackgroundColor: "#ffffff",
      pageTextColor: "#00695c",
      buttonBackgroundColor: "#90a4ae",
      buttonTextColor: "#000000",
      cardBackground: "#455a64",
      cardTextColor: "#ffffff",
      favicon: "397/favImage/af7dc8e8-b2b3-4b97-96b6-c6ada687dc73.png",
      websiteName: "786 Rider Website",
      fontStyle: "poetsen-one-regular",
      brandId: 397,
      autorotate: false
    };
  }

  private handleError(message: string) {
    this.showErrorMessage(message);
  }
  getDynamicHeader() {
    this.ecomWebAuthService.apiEcomWebAuthGetLatestDynamicHeaderByBrandIdGet(this.appData.brandId)
      .subscribe({
        next: (res) => {
               if (res.data) {
            
            this.headerStyles = {
              id: res.data.id ?? 0,
              islogoReq: res.data.islogoReq ?? false,
              weblogo: res.data.weblogo ?? null,
              moblogo: res.data.moblogo ?? null,
              dualHeader: res.data.dualHeader ?? false,
              logoAlignment: res.data.logoAlignment ?? "Left",
              headerHight: res.data.headerHight ?? "78",
              isSearchReq: res.data.isSearchReq ?? false,
              searchType: res.data.searchType ?? "0",
              isProfileReq: res.data.isProfileReq ?? false,
              wishlistReq: res.data.wishlistReq ?? false,
              wishlistType: res.data.wishlistType ?? "",
              cartReq: res.data.cartReq ?? false,
              cartType: res.data.cartType ?? null,
              menuReq: res.data.menuReq ?? false,
              categoryReq: res.data.categoryReq ?? false,
              productReq: res.data.productReq ?? false,
              nonCategory: res.data.nonCategory ?? false,
              brandId: res.data.brandId ?? 0,
              fontStyle: res.data.fontStyle ?? "Roboto",
              fontHeight: res.data.fontHeight ?? "20",
              fontWeight: res.data.fontWeight ?? "400",
              headerBackgroundColor: res.data.headerBackgroundColor ?? "#ffffff",
              headerTextColor: res.data.headerTextColor ?? "#000000",
              solidHeader: res.data.solidHeader ?? "Solid",
              dropDownStyle: res.data.dropDownStyle ?? "0",
              logoHeight: res.data.logoHeight ?? "82",
              logoWidth: res.data.logoWidth ?? "128",
              logoBorderRadius: res.data.logoBorderRadius ?? "0",
              navLinksEndIcon: res.data.navLinksEndIcon ?? false,
              showAllCategory: res.data.showAllCategory ?? false,
              getBasePath: res.data.getBasePath ?? "",
              dynamicNavLinkModelGet: res.data.dynamicNavLinkModelGet ?? {
                id: 0,
                navLinkName: "",
                navLinkEnd: "",
                brandId: 0,
                dynamicHeaderId: 0,
                isDelete: false,
                isVisible: false
              }
            };

           

            this.accountService._headerStyles.next(this.headerStyles);

            // Optional processing for dynamicNavLinkModelGet
           
          }
          else{
            this.headerStyles = {
              id: 0,
              islogoReq: false,
              weblogo: "",
              moblogo: "null",
              dualHeader: false,
              logoAlignment:"Left",
              headerHight:  "78",
              isSearchReq: false,
              searchType: "0",
              isProfileReq:false,
              wishlistReq: false,
              wishlistType: "",
              cartReq:false,
              cartType: "",
              menuReq:  false,
              categoryReq: false,
              productReq:  false,
              nonCategory:  false,
              brandId:  0,
              fontStyle:"Roboto",
              fontHeight: "20",
              fontWeight: "400",
              headerBackgroundColor: "#ffffff",
              headerTextColor:  "#000000",
              solidHeader:"Solid",
              dropDownStyle: "0",
              logoHeight:"82",
              logoWidth:"128",
              logoBorderRadius: "0",
              navLinksEndIcon: false,
              showAllCategory: false,
              getBasePath: "",
              dynamicNavLinkModelGet:  {
                id: 0,
                navLinkName: "",
                navLinkEnd: "",
                brandId: 0,
                dynamicHeaderId: 0,
                isDelete: false,
                isVisible: false
              }
            };

           

            this.accountService._headerStyles.next(this.headerStyles);
          }
        },
        error: (error) => {
          this.showErrorMessage(error.message || 'An error occurred while fetching the data.');
        }
      });
  }


  //   getDynamicHeader() {
  //     this.ecomWebAuthService.apiEcomWebAuthGetLatestDynamicHeaderByBrandIdGet(this.appData.brandId).subscribe(res => {
  //       if (res.data) {
  //         this.headerStyles = res.data;
  //         // this.allHeaderCategories.fontStyle
  // console.log("header colors",this.headerStyles)
  //         this.accountService._headerStyles.next(this.headerStyles)
  //         // this.selectedLinksEnd = this.allHeaderCategories?.dynamicNavLinkModel.NavLinkEnd[0].split(',')
  //         // this.selectedToppings = this.allHeaderCategories?.dynamicNavLinkModel.NavLinkName[0].split(',')

  //       }

  //     })
  //   }

  showErrorMessage(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'center'
    });
  }

}
