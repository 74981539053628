/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ShipmentTrackNew { 
    awbCode?: string | null;
    courierCompanyId?: number;
    shipmentId?: number;
    pickupDate?: string | null;
    deliveredDate?: any | null;
    weight?: string | null;
    currentStatus?: string | null;
    consigneeName?: string | null;
    courierAgentDetails?: any | null;
    courierName?: string | null;
    edd?: string | null;
}

