/**
 * E-Commerce Bussiness Application(Contains Web and Mobile API\'s)
 * API - Ecom
 *
 * The version of the OpenAPI document: Ecom
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IpAddressLogs { 
    id?: number;
    brandID?: number;
    ipAddress?: string | null;
    timestamp?: string;
    productIds?: number | null;
    userId?: number | null;
}

